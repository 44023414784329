import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { useMutation, useQuery } from '@apollo/react-hooks';
import * as IUserInfo from 'data-layer/mutations/__graphql__/UpdateUserInfo';
// import { ReactComponent as IconDelete } from '../assets/icon-delete.svg';
import {
  AgreementFrame,
  PageContainer,
  Button,
  LogoutButton,
  ModalDialog,
  Input,
  CodeInput,
  ProfileForm,
} from '../components';
import { getMultilineTranslation } from 'utils/utils';
import {
  colors,
  unit,
  largeText,
  mediumText,
  smallText,
  breakpointLarge,
  breakpointMedium,
  showForMobile,
} from '../styles';

import { SMS_CODE_LENGTH } from '../components/loginSmsForm';
import { ClientContext, constants, navigation } from 'utils';
import { UPDATE_USER_INFO } from 'data-layer/mutations';
import { ICred } from 'data-layer/types/ICred';
import { SexEHR } from 'data-layer/types';
import { GET_BUSINESS_INFO } from 'data-layer/queries';
import {
  GetBusinessInfo,
  GetBusinessInfoVariables,
} from 'data-layer/queries/__graphql__/GetBusinessInfo';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProfileInfoScreenProps extends RouteComponentProps {}

const ProfileInfoScreen: React.FC<ProfileInfoScreenProps> = () => {
  const { t } = useTranslation();
  const clientContext = useContext(ClientContext);
  const cred: ICred = {
    user: clientContext.user,
    token: clientContext.token,
  };
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [removeProfileModal, setRemoveProfileModal] = useState(false);
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [changePhoneModal, setChangePhoneModal] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);

  const generalInfoData = useQuery<GetBusinessInfo, GetBusinessInfoVariables>(GET_BUSINESS_INFO, {
    variables: {
      ID: clientContext.businessId,
    },
    skip: !clientContext.businessId,
  });
  const requireAgreement =
    generalInfoData?.data?.getBusinessInfo.widget_configuration.requireAgreement;
  const requireAgreementLink =
    generalInfoData?.data?.getBusinessInfo.widget_configuration.requireAgreementLink;

  const [UpdateUserInfo, UpdateUserInfoStatus] = useMutation<
    IUserInfo.UpdateUserInfo,
    IUserInfo.UpdateUserInfoVariables
  >(UPDATE_USER_INFO, {
    onCompleted() {
      setShowEditProfile(false);
      clientContext.refetchClient();
    },
  });

  const phoneString = clientContext.clientInfo.phone;
  const [userPhone, setUserPhone] = useState(
    clientContext.nationalMode ? `${phoneString}` : `+${phoneString}`,
  );
  const {
    name = '',
    surname = '',
    middleName = '',
    gender = SexEHR.NOT_SPECIFIED,
    email,
    date,
  } = clientContext.clientInfo;
  const birthDate = date ? moment.utc(date).format(constants.DATE_FORMAT) : '';
  const getGenderStr = (g: number) => {
    switch (g) {
      case 0:
        return t('screens.appointments.form.genderMale');
      case 1:
        return t('screens.appointments.form.genderFemale');
      default:
        return t('screens.appointments.form.genderSelect');
    }
  };
  // const onShowRemoveDialog = () => {
  //   setRemoveProfileModal(true);
  // };
  const onCancelRemoveDialog = () => {
    setRemoveProfileModal(false);
  };
  const onConfirmRemoveDialog = () => {
    setRemoveProfileModal(false);
  };
  // const onShowEmailDialog = () => {
  //   setChangeEmailModal(true);
  // };
  const onCancelEmailDialog = () => {
    setChangeEmailModal(false);
  };
  const onConfirmEmailDialog = () => {
    setChangeEmailModal(false);
  };
  // const onShowPhoneDialog = () => {
  //   setChangePhoneModal(true);
  // };
  const onCancelPhoneDialog = () => {
    setChangePhoneModal(false);
  };
  const onConfirmPhoneDialog = () => {
    setChangePhoneModal(false);
  };

  const renderProfileEdit = () => {
    if (UpdateUserInfoStatus.loading) {
      return <>Loading</>;
    }
    if (UpdateUserInfoStatus.error) {
      return <>Error</>;
    }
    return (
      <ProfileForm
        onSave={UpdateUserInfo}
        cred={cred}
        clientContext={clientContext}
        showSkipBtn
        customLabel={t('components.profileForm.labalChangeData')}
        onSkip={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          navigation.goBack();
        }}
      />
    );
  };

  const renderProfileInfo = () => {
    return (
      <Inner>
        <MobTitle>{t('title.profile', { userName: name })}</MobTitle>
        {/* <ProfilePicBlockStyled /> */}
        <Row>
          <ColTop>
            <Caption>{`${name} ${middleName} ${surname}`}</Caption>
            <LinkStyledDiv onClick={() => setShowEditProfile(true)}>
              {t('components.profileInfo.changeName')}
            </LinkStyledDiv>
            {/* <ButtonEmail variant="link" onClick={onShowEmailDialog}>
              {t('components.profileInfo.addEmail')}
            </ButtonEmail> */}
            <ModalDialog
              isOpen={changeEmailModal}
              title={t('components.profileInfo.changeEmailModalTitle')}
              confirmButtonTitle={t('components.profileInfo.changeEmailModalConfirmBtn')}
              cancelButtonTitle={t('components.profileInfo.changeEmailModalCancelBtn')}
              onRequestClose={onCancelEmailDialog}
              onConfirm={onCancelEmailDialog}
              onCancel={onConfirmEmailDialog}
            >
              {getMultilineTranslation(t('components.profileInfo.changeEmailModalDescription'))}
              <Input
                placeholder={t('components.profileInfo.changeEmailModalInputPlaceholder')}
                style={{ margin: `${unit}vh 0` }}
              />
            </ModalDialog>
          </ColTop>
          <ColGender>
            <Caption>{getGenderStr(gender)}</Caption>
            <LinkStyledDiv onClick={() => setShowEditProfile(true)}>
              {t('components.profileInfo.changeGender')}
            </LinkStyledDiv>
          </ColGender>
          <ColTop>
            <Caption>{userPhone}</Caption>
            {/* <ButtonStyled variant="link" onClick={onShowPhoneDialog}>
              {t('components.profileInfo.changePhone')}
            </ButtonStyled> */}
            <ModalDialog
              isOpen={changePhoneModal}
              title={t('components.profileInfo.changePhoneModalTitle')}
              confirmButtonTitle={t('components.profileInfo.changePhoneModalConfirmBtn')}
              cancelButtonTitle={t('components.profileInfo.changePhoneModalCancelBtn')}
              onRequestClose={onCancelPhoneDialog}
              onConfirm={onCancelPhoneDialog}
              onCancel={onConfirmPhoneDialog}
            >
              {getMultilineTranslation(t('components.profileInfo.changePhoneModalDescription'))}
              <div style={{ margin: `${unit}vh 0` }}>
                <PhoneLabel>{t('components.profileInfo.changePhoneModalLabel')}</PhoneLabel>
                <Input
                  required
                  type="tel"
                  name="phone"
                  value={userPhone}
                  placeholder="+7 ..."
                  inputMode="numeric"
                  onChange={(event) => {
                    setUserPhone(event.target.value);
                  }}
                />
                <CodeLabel>{t('components.auth.smsCode')}</CodeLabel>
                <CodeInput
                  length={SMS_CODE_LENGTH}
                  onChange={(code) => {
                    // eslint-disable-next-line no-console
                    // celnoos.log(code);
                  }}
                />
              </div>
            </ModalDialog>
          </ColTop>
        </Row>
        <Row>
          {birthDate ? (
            <ColTop>
              <Caption>{`${birthDate}`}</Caption>
              <LinkStyledDiv onClick={() => setShowEditProfile(true)}>
                {t('components.profileInfo.changeBirthDate')}
              </LinkStyledDiv>
            </ColTop>
          ) : (
            ''
          )}
          {email ? (
            <ColTop>
              <Caption>{`${email}`}</Caption>
            </ColTop>
          ) : (
            <ColTop>
              <Caption> </Caption>
            </ColTop>
          )}
        </Row>
        <Footer>
          <ColBottom>
            <ModalDialog
              isOpen={agreementModal}
              cancelButtonTitle={t('components.agreement.modalClose')}
              onCancel={() => setAgreementModal(false)}
              onRequestClose={() => setAgreementModal(false)}
            >
              <AgreementFrame src={requireAgreementLink} />
            </ModalDialog>
            {requireAgreement && requireAgreementLink && (
              <ButtonStyled variant="link" onClick={() => setAgreementModal(true)}>
                {t('components.profileInfo.usageAgreement')}
              </ButtonStyled>
            )}
            <LogoutButton>{t('components.profileInfo.logout')}</LogoutButton>
            {
              // Seems we don't need it right now
              /* <ButtonStyled variant="link">
              {t('components.profileInfo.logoutEMK')}
            </ButtonStyled> */
            }
          </ColBottom>
          <ColBottom style={{ flexDirection: 'column-reverse' }}>
            {/* <ButtonStyled variant="link" onClick={onShowRemoveDialog}>
              <IconDeleteStyled />
              {t('components.profileInfo.removeProfile')}
            </ButtonStyled> */}
            <ModalDialog
              isOpen={removeProfileModal}
              title={t('components.profileInfo.removeProfileModalTitle')}
              cancelButtonTitle={t('components.profileInfo.removeProfileModalCancelBtn')}
              confirmButtonTitle={t('components.profileInfo.removeProfileModalConfirmBtn')}
              onRequestClose={onCancelRemoveDialog}
              onCancel={onCancelRemoveDialog}
              onConfirm={onConfirmRemoveDialog}
            >
              {t('components.profileInfo.removeProfileModalDescription')}
            </ModalDialog>
          </ColBottom>
        </Footer>
      </Inner>
    );
  };
  return (
    <PageContainer
      layoutClassName="profile"
      title={t('title.profile', { userName: name })}
      contentCSS={{ alignItems: 'stretch' }}
      topNav
      sideNav
      backLink
    >
      {showEditProfile ? renderProfileEdit() : renderProfileInfo()}
    </PageContainer>
  );
};
export default ProfileInfoScreen;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

// const ProfilePicBlockStyled = styled(ProfilePicBlock)({
//   [`@media screen and (max-width: ${breakpointMedium}px)`]: {
//     marginBottom: unit * 3,
//   },
// });

const LinkStyledDiv = styled('div')(smallText, {
  color: colors.textAccent,
  cursor: 'pointer',
  textDecoration: 'none',
});

const ButtonStyled = styled(Button)({
  textAlign: 'initial',
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

// const ButtonEmail = styled(ButtonStyled)({
//   marginTop: unit * 6,
//   [`@media screen and (max-width: ${breakpointMedium}px)`]: {
//     marginTop: unit * 3,
//   },
// });

const ColTop = styled('div')({
  paddingTop: unit * 7,
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    paddingTop: unit * 2,
    paddingBottom: unit * 2,
  },
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    paddingTop: 0,
    paddingBottom: unit * 3,
    textAlign: 'center',
  },
});

const ColGender = styled(ColTop)({
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    display: 'none',
  },
});

const ColBottom = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '&:not(:last-child)': {
    [`[dir="ltr"] &`]: {
      marginRight: unit,
    },
    [`[dir="rtl"] &`]: {
      marginLeft: unit,
    },
  },
  '& > *:not(:last-child)': {
    marginBottom: unit * 3.2,
  },
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    '& > *': {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: `${unit * 3}px !important`,
    },
  },
});

const MobTitle = styled('h2')(largeText, showForMobile, {
  marginTop: unit * 5,
  marginBottom: unit * 6,
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    textAlign: 'center',
  },
});

const Inner = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  [`@media screen and (min-width: ${breakpointMedium + 1}px)`]: {
    [`[dir="ltr"] &`]: {
      paddingLeft: unit * 19.2,
    },
    [`[dir="rtl"] &`]: {
      paddingRight: unit * 19.2,
    },
  },
});

const Row = styled('div')({
  display: 'flex',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    justifyContent: 'flex-start',
    '& > *:not(:last-child)': {
      [`[dir="ltr"] &`]: {
        marginRight: unit * 7,
      },
      [`[dir="rtl"] &`]: {
        marginLeft: unit * 7,
      },
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    flexDirection: 'column',
  },
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    alignItems: 'center',
  },
});

const Caption = styled('div')(mediumText, {
  color: colors.text,
  marginBottom: unit * 1.7,
});

const Footer = styled('div')({
  display: 'flex',
  marginTop: 'auto',
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    flexDirection: 'column',
  },
});

// const IconDeleteStyled = styled(IconDelete)({
//   marginRight: unit,
// });

const PhoneLabel = styled('div')(largeText, {
  fontWeight: 400,
  marginBottom: unit * 2,
});

const CodeLabel = styled('div')(smallText, {
  fontWeight: 500,
  margin: `${unit * 3}px 0 ${unit * 2}px`,
});
