import { gql } from 'apollo-boost';

export const GET_EHR_APPOINTMENT_RESULT = gql`
  query GetEHRAppointmentResult(
    $businessID: String!
    $clientId: String!
    $visitId: String!
    $user: String!
    $token: String!
  ) {
    getEHRAppointmentResult(
      businessID: $businessID
      clientId: $clientId
      visitId: $visitId
      user: $user
      token: $token
    ) @client {
      id
      patientId
      created
      start
      duration
      business {
        name
        location
      }
      doctor {
        name
        surname
      }
      anamnesis
      medicalExaminationResult
      diagnosis {
        description
        cd10
      }
      recommendations {
        id
        created
        title
        services {
          name
        }
        type
        status
        period {
          begin
          end
        }
        strictPeriod {
          begin
          end
        }
        preparations
        requiredPreparations
        appointmentResultId
      }
      scheduledProcedures {
        id
        created
        title
        services {
          name
        }
        type
        status
        period {
          begin
          end
        }
        strictPeriod {
          begin
          end
        }
        preparations
        requiredPreparations
        appointmentResultId
      }
      prescriptions {
        id
        created
        recorderDoctor {
          name
          surname
        }
        dosageText
        reasonText
        validityPeriod {
          begin
          end
        }
        numberOfRepeats
        title
        medications {
          form
          amount
          expirationDate
        }
      }
      diagnosticReportIds
    }
  }
`;
