import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { SmallStartRecord } from 'components';
import NewRecord from 'components/NewRecord';
import { GET_POPULAR_TAXONOMIES, GET_BUSINESS } from 'data-layer/queries';
import { getBusiness, getBusinessVariables } from 'data-layer/queries/__graphql__/getBusiness';
import {
  GetPopularTaxonomies_getPopularTaxonomies_taxonomies,
  GetPopularTaxonomies_getPopularTaxonomies_resources,
  GetPopularTaxonomies_getPopularTaxonomies_locations,
  GetPopularTaxonomies,
  GetPopularTaxonomiesVariables,
} from 'data-layer/queries/__graphql__/GetPopularTaxonomies';
import { hideForMobile, showForMobile } from 'styles';
import { urlManager } from 'utils';

export const NewRecordScreenTable: React.FC = () => {
  const MAX_RECORD_LENGTH = 5;

  const businessID = urlManager.getBusinessId();
  const networkID = urlManager.getNetworkId();
  let taxonomies: GetPopularTaxonomies_getPopularTaxonomies_taxonomies[] = [];
  let resources: GetPopularTaxonomies_getPopularTaxonomies_resources[] = [];
  let locations: GetPopularTaxonomies_getPopularTaxonomies_locations[] = [];
  const popularNetworkData = useQuery<GetPopularTaxonomies, GetPopularTaxonomiesVariables>(
    GET_POPULAR_TAXONOMIES,
    {
      variables: {
        networkID,
      },
      fetchPolicy: 'cache-and-network',
      skip: !networkID,
    },
  );
  if (popularNetworkData?.data?.getPopularTaxonomies) {
    taxonomies = popularNetworkData?.data?.getPopularTaxonomies?.taxonomies;
    resources = popularNetworkData?.data?.getPopularTaxonomies?.resources;
    locations = popularNetworkData?.data?.getPopularTaxonomies?.locations;
  }
  const popularSingleData = useQuery<getBusiness, getBusinessVariables>(GET_BUSINESS, {
    variables: {
      id: businessID,
    },
    fetchPolicy: 'cache-and-network',
    skip: !!networkID,
  });
  if (popularSingleData?.data?.getBusinessByID) {
    const popularServices = popularSingleData?.data.getBusinessByID.top_services?.services || [];
    const allTax = popularSingleData?.data?.getBusinessByID.taxonomies;
    const allRes = popularSingleData?.data?.getBusinessByID.resources;
    if (popularServices?.length > 0) {
      taxonomies = allTax.filter((tax) => {
        return popularServices.includes(tax.id);
      });
    }
    resources = allRes;
  }

  return (
    <>
      <StartRecordStyled variant="big" />
      <SmallStartRecordStyled variant="card" />
      <NewRecord
        taxonomies={taxonomies.slice(0, MAX_RECORD_LENGTH)}
        resources={resources.slice(0, MAX_RECORD_LENGTH)}
        locations={locations.slice(0, MAX_RECORD_LENGTH)}
      />
    </>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const startRecordStyles = css({ marginBottom: 0 });

const StartRecordStyled = styled(SmallStartRecord)(hideForMobile, startRecordStyles);

const SmallStartRecordStyled = styled(SmallStartRecord)(showForMobile, startRecordStyles);
