import React from 'react';
import styled from '@emotion/styled';
import { lighten } from 'polished';

import { unit, colors, flexCenter, smallText } from '../styles';

interface IButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  variant?: 'transparent' | 'grey' | 'link';
}

export default styled('button')(flexCenter, smallText, ({ variant }: IButtonProps) => {
  let backgroundColor;
  let color;
  switch (variant) {
    case 'transparent':
      backgroundColor = 'transparent';
      color = colors.text;
      break;
    case 'grey':
      backgroundColor = colors.darkBackground;
      color = colors.text;
      break;
    case 'link':
      backgroundColor = 'transparent';
      color = colors.textAccent;
      break;
    default:
      backgroundColor = colors.secondary;
      color = colors.mainBackground;
      break;
  }
  return {
    minWidth: variant === 'link' ? 'auto' : `${unit * 20}px`,
    padding: variant === 'link' ? 0 : `9px ${unit * 2.5}px`,
    border: 'none',
    borderRadius: unit * 10,
    fontFamily: 'inherit',
    textDecoration: 'none',
    backgroundColor,
    cursor: 'pointer',
    outline: 'none',
    color,
    transition: 'all 0.3s ease-in-out',
    ':not([:disabled]):hover': {
      backgroundColor: lighten(0.1, backgroundColor),
    },
    ':active': {
      backgroundColor: lighten(0.2, backgroundColor),
    },
    '&[disabled]': {
      opacity: 0.6,
      cursor: 'not-allowed',
    },
  };
});
