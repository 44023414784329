import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import moment from 'moment';
import ReactDatePicker from 'react-date-picker';

import { ReactComponent as IconCalendar } from '../assets/icon-calendar.svg';
import { ReactComponent as IconError } from '../assets/icon-error.svg';
import { ClientContext, constants } from '../utils';
import { breakpointSmall, colors, unit } from '../styles';

interface DatepickerProps {
  selected: string;
  error: boolean;
  onDateChange: (dateStr: string) => void;
}

const Datepicker: React.FC<DatepickerProps> = ({
  selected,
  error,
  onDateChange,
}: DatepickerProps) => {
  const { lang } = useContext(ClientContext);
  const selectedDate = moment(selected, constants.DATE_FORMAT).toDate();
  const [date, setDate] = useState<Date | null>(selectedDate);
  const onChangeHandler = (dateVal: Date | Date[]) => {
    if (!Array.isArray(dateVal)) {
      setDate(dateVal);
      onDateChange(moment(dateVal).format(constants.DATE_FORMAT));
    }
  };
  const [locale] = lang.code.split('-');
  return (
    <ReactDatePickerStyled
      value={date}
      locale={locale}
      calendarIcon={error ? <StyledIconError /> : <StyledIconCalendar />}
      clearIcon={null}
      onChange={onChangeHandler}
    />
  );
};

export default Datepicker;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const iconStyles = css({
  pointerEvents: 'none',
});

const StyledIconCalendar = styled(IconCalendar)(iconStyles);
const StyledIconError = styled(IconError)(iconStyles);

const ReactDatePickerStyled = styled(ReactDatePicker)({
  width: '100%',
  maxWidth: unit * 29.2,
  '.react-date-picker': {
    '&__inputGroup > input:not(.react-date-picker__inputGroup__input)': {
      display: 'none',
    },
    '&__wrapper': {
      padding: `${unit * 1.1}px ${unit * 2.4}px`,
      border: `1px solid transparent`,
      borderRadius: unit * 10,
      fontSize: 26,
      lineHeight: 1.153,
      backgroundColor: colors.darkBackground,
      transition: 'all 0.3s ease-in-out',
      [`[dir="ltr"] &`]: {
        paddingRight: unit * 1.5,
      },
      [`[dir="rtl"] &`]: {
        paddingLeft: unit * 1.5,
      },
      '&.react-date-picker--open': {
        borderColor: colors.secondary,
      },
      '.react-date-picker__button': {
        outline: 'none',
      },
    },
    '&__calendar': {
      maxWidth: unit * 29,
      '&--open': {
        [`@media screen and (max-width: ${breakpointSmall}px)`]: {
          top: 'auto !important',
          bottom: '100% !important',
          height: 'auto !important',
        },
      },
    },
  },
  '.react-calendar': {
    borderColor: colors.secondary,
    borderRadius: unit,
    '&__navigation__label': {
      whiteSpace: 'nowrap',
    },
  },
});
