import React from 'react';
import ReactDOM from 'react-dom';
import { Global } from '@emotion/core';
import { createApolloClient } from './data-layer';
import { ApolloProvider, useQuery } from '@apollo/react-hooks';
import { HelmetProvider } from 'react-helmet-async';
import './utils/i18n';
import Pages from './pages';
import Login from './pages/login';
import SetNewPassword from './pages/setNewPassword';
import globalStyles from './styles';

import * as UserDataTypes from './data-layer/queries/__graphql__/UserData';

import { urlManager } from 'utils/urlManager';
import { constants } from 'utils';
import ProfileEditScreen from 'pages/profileEditScreen';
import { USER_LOGIN_DATA } from 'data-layer/queries';
import { ContentDirection, MODAL_ELEMENT_ID, ClientContextProvider } from 'components';

interface IgbookingWidgetSetup {
  preload: boolean;
  url?: string;
  css?: string;
  networkId?: string;
  businessId?: string;
  widgetWrapperId?: string;
}

declare global {
  interface Window {
    gbookingWidgetSetup: IgbookingWidgetSetup;
    GBooking: {
      openWidget(params: unknown): void;
      closeWidget(): void;
    };
  }
}
const gbookingWidgetSetup: IgbookingWidgetSetup = {
  preload: false,
  css: 'iframe',
  widgetWrapperId: MODAL_ELEMENT_ID,
  url: constants.devConfig ? 'http://widgetv3.dev.gbooking.ru/' : undefined,
};
const businessID = urlManager.getBusinessId();
const networkID = urlManager.getNetworkId();
if (networkID) {
  gbookingWidgetSetup.networkId = networkID;
} else if (businessID) {
  gbookingWidgetSetup.businessId = businessID;
}

window.gbookingWidgetSetup = gbookingWidgetSetup;

async function main() {
  const apolloClient = await createApolloClient();
  function renderLogin() {
    return (
      <ClientContextProvider>
        <ContentDirection />
        <Login />
      </ClientContextProvider>
    );
  }
  function IsLoggedIn() {
    const { data } = useQuery<UserDataTypes.UserData>(USER_LOGIN_DATA);
    if (!data) {
      return renderLogin();
    }
    const { isLoggedIn, askNewPass, showProfileScreen } = data;

    if (askNewPass && isLoggedIn) {
      return (
        <ClientContextProvider>
          <ContentDirection />
          <SetNewPassword />
        </ClientContextProvider>
      );
    }

    if (showProfileScreen && isLoggedIn) {
      return (
        <ClientContextProvider>
          <ContentDirection />
          <ProfileEditScreen />
        </ClientContextProvider>
      );
    }

    return isLoggedIn && !askNewPass ? <Pages /> : renderLogin();
  }

  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <Global styles={globalStyles} />
      <HelmetProvider>
        <IsLoggedIn />
      </HelmetProvider>
    </ApolloProvider>,
    document.getElementById('root'),
  );
}

// eslint-disable-next-line @typescript-eslint/no-floating-promises
main();
