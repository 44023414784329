import { gql } from 'apollo-boost';

export const GET_EHR_APPOINTMENTS = gql`
  query GetEHRAppointments(
    $businessID: String!
    $clientId: String!
    $patientId: String!
    $user: String!
    $token: String!
    $offset: Int
  ) {
    getPatientAppointments(
      businessID: $businessID
      clientId: $clientId
      patientId: $patientId
      user: $user
      token: $token
      offset: $offset
    ) @client {
      appointments {
        id
        patientId
        created
        start
        doctor {
          id
          name
          surname
          specialization {
            name
          }
        }
        services {
          id
          name
          price {
            value
          }
          duration
        }
        clientPrice {
          value
        }
        confirmationStatus
        resultId
        source
      }
    }
  }
`;
