import { gql } from 'apollo-boost';

export const GET_EHR_DIAGNOSTIC_RESULT = gql`
  query GetEHRDiagnosticResult($visitId: String!) {
    getEHRDiagnosticResult(visitId: $visitId) @client {
      id
      type
      effectivePeriod {
        begin
        end
      }
      issuedDate
      result {
        id
        createdDate
        type
        observationKey
        effectivePeriod {
          begin
          end
        }
        issuedDate
        performerDoctor {
          id
          name
          surname
        }
        performerBusiness {
          name
          location
        }
        value {
          serializedValue
          code
          value
        }
        note
        interpretation
        ranges {
          low
          high
          type
          age {
            begin
            end
          }
          text
        }
        components {
          type
          value {
            serializedValue
            code
            value
          }
          interpretation
          ranges {
            low
            high
            type
            age {
              begin
              end
            }
            text
          }
        }
      }
      resultInterpreter {
        id
        name
        surname
      }
      resultInterpretation
      imagineMedia
      attachments
      services {
        id
        name
      }
      category
    }
  }
`;
