import { AppointmentModel } from 'medme-ehr-js-sdk/dist/es5/models/AppointmentModel';
import { AppointmentResultModel } from 'medme-ehr-js-sdk/dist/es5/models/AppointmentResultModel';
import { DiagnosticReportModel } from 'medme-ehr-js-sdk/dist/es5/models/DiagnosticReportModel';
import { AppointmentConfirmationStatus } from 'medme-ehr-js-sdk/dist/es5/types';
import { config } from 'utils';

export const getAppointmentResult = (res: AppointmentResultModel) => {
  return {
    __typename: 'PatientAppointmentResult',
    id: res.id,
    patientId: res.patientId,
    created: res.created,
    start: res.start,
    duration: res.duration,
    anamnesis: res.anamnesis || [],
    medicalExaminationResult: res.medicalExaminationResult || [],
    diagnosticReportIds: res.diagnosticReportIds || [],
    business: {
      name: res.business.name || '',
      location: res.business.location || '',
      __typename: 'EHRBusinessInfo',
    },
    doctor: {
      name: res.doctor.name || '',
      surname: res.doctor.surname || '',
      __typename: 'EHRDoctorInfo',
    },
    diagnosis: (res.diagnosis || []).map((d) => {
      return {
        __typename: 'Diagnosis',
        ...d,
      };
    }),
    recommendations: (res.recommendations || []).map((r) => {
      return {
        __typename: 'Procedure',
        ...r,
        services: (r.services || []).map((s) => {
          return {
            __typename: 'EHRServices',
            name: s.name,
          };
        }),
        period: {
          ...r.period,
          __typename: 'Period',
        },
        strictPeriod: {
          ...r.strictPeriod,
          __typename: 'Period',
        },
      };
    }),
    scheduledProcedures: (res.scheduledProcedures || []).map((r) => {
      return {
        __typename: 'Procedure',
        ...r,
        services: (r.services || []).map((s) => {
          return {
            __typename: 'EHRServices',
            name: s.name,
          };
        }),
        period: {
          ...r.period,
          __typename: 'Period',
        },
        strictPeriod: {
          ...r.strictPeriod,
          __typename: 'Period',
        },
      };
    }),
    prescriptions: (res.prescriptions || []).map((p) => {
      return {
        ...p,
        recorderDoctor: {
          ...p.recorderDoctor,
          name: p.recorderDoctor.name || '',
          surname: p.recorderDoctor.surname || '',
          __typename: 'EHRDoctorInfo',
        },
        validityPeriod: {
          ...p.validityPeriod,
          __typename: 'Period',
        },
        medications: (p.medications || []).map((m) => {
          return {
            ...m,
            __typename: 'Medication',
          };
        }),
        __typename: 'PrescriptionInfo',
      };
    }),
  };
};

export interface fakeEhrApp {
  result: { appointments: AppointmentModel[] };
}

export interface fakeEhrDiagnostic {
  result: { diagnosticReports: DiagnosticReportModel[] };
}

export const parseResponce = (
  appointments: AppointmentModel[],
  clientVisits: AppointmentModel[],
) => {
  const formattedAppointments: AppointmentModel[] = config.MOCK_EHR
    ? appointments
    : (appointments || []).map((app) => app.toJson() as AppointmentModel);
  const filteredClientVisits = formattedAppointments.filter((x) => !!x && !!x.resultId);

  // eslint-disable-next-line no-param-reassign
  clientVisits = clientVisits.concat(clientVisits, filteredClientVisits);

  return clientVisits.map((app) => {
    return {
      ...app,
      id: app.id,
      created: app.created,
      start: app.start,
      source: app.source,
      confirmationStatus:
        app.confirmationStatus || AppointmentConfirmationStatus.ConfirmedByBusiness,
      doctor: {
        ...app.doctor,
        id: app.doctor.id || '',
        name: app.doctor.name || '',
        surname: app.doctor.surname || '',
        specialization: {
          ...(app.doctor.specialization || ''),
          __typename: 'EHRSpecialization',
        },
        __typename: 'EHRDoctorInfo',
      },
      services: app.services.map((s) => {
        return {
          ...s,
          id: s.id || '',
          name: s.name || '',
          duration: s.duration || '',
          price: {
            ...s.price,
            __typename: 'Price',
          },
          __typename: 'AppointmentTaxonomy',
        };
      }),
      clientPrice: {
        ...app.clientPrice,
        __typename: 'Price',
      },
      __typename: 'PatientAppointment',
    };
  });
};

export const parseDiagnostic = (clientDiagnostics: DiagnosticReportModel[]) => {
  return clientDiagnostics.map((app) => {
    return {
      ...app,
      id: app.id,
      effectivePeriod: {
        ...app.effectivePeriod,
        __typename: 'Period',
      },
      result: (app.result || []).map((res) => {
        return {
          ...res,
          __typename: 'Observation',
          effectivePeriod: {
            ...res.effectivePeriod,
            __typename: 'Preiod',
          },
          performerDoctor: {
            ...res.performerDoctor,
            __typename: 'EHRDoctorInfo',
          },
          performerBusiness: {
            ...res.performerBusiness,
            __typename: 'EHRBusinessInfo',
          },
          value: {
            ...res.value,
            __typename: 'ObservationValue',
          },
          ranges: (res.ranges || []).map((range) => {
            return {
              ...range,
              age: {
                ...range.age,
                __typename: 'Preiod',
              },
              __typename: 'ObservationRange',
            };
          }),
          components: (res.components || []).map((component) => {
            return {
              ...component,
              value: {
                ...component.value,
                __typename: 'ObservationValue',
              },
              ranges: (component.ranges || []).map((range) => {
                return {
                  ...range,
                  age: {
                    ...range.age,
                    __typename: 'Preiod',
                  },
                  __typename: 'ObservationRange',
                };
              }),
              __typename: 'ObservationComponent',
            };
          }),
        };
      }),
      resultInterpreter: (app.resultInterpreter || []).map((r) => {
        return {
          ...r,
          __typename: 'EHRDoctorInfo',
        };
      }),
      services: (app.services || []).map((service) => {
        return {
          ...service,
          __typename: 'EHRServices',
        };
      }),
      __typename: 'PatientDiagnostic',
    };
  });
};
