import moment from 'moment';
import React from 'react';
import styled from '@emotion/styled';
import { Link } from '@reach/router';

import { paths } from '../utils/routing';
import { breakpointSmall, colors, unit } from '../styles';
import { urlManager } from 'utils/urlManager';
import { GetEHRAppointments_getPatientAppointments_appointments } from 'data-layer/queries/__graphql__/GetEHRAppointments';

interface EHRAppointmentBlockProps {
  appointmentData: GetEHRAppointments_getPatientAppointments_appointments;
}

type cardVariant = 'past' | 'future' | undefined;

export const EHRAppointmentBlock: React.FC<EHRAppointmentBlockProps> = React.memo(
  (props: EHRAppointmentBlockProps) => {
    const { appointmentData } = props;
    const { start, doctor, services, resultId } = appointmentData;
    const queryString = urlManager.getQueryString();
    const cardVariant = 'past';
    const startTime = moment.utc(start).calendar(null, { sameElse: 'D MMMM YYYY HH:mm' });
    function getWorkerName() {
      const { name, surname } = doctor;
      return `${surname} ${name}`;
    }

    function getTitle() {
      let title = getWorkerName();
      if (services?.length) {
        title = services[0].name;
      }

      return `${title}`;
    }

    return (
      <Card variant={cardVariant}>
        <StyledLink to={`${paths.ehrAppointmentPath(resultId)}?${queryString}`}>
          <CardTitle title={getTitle()}>{getTitle()}</CardTitle>
          <CardDate>{startTime}</CardDate>
          <CardResource>{getWorkerName()}</CardResource>
        </StyledLink>
      </Card>
    );
  },
);

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: cardVariant;
}

const Card = styled('div')((props: ICardProps) => {
  let background;
  switch (props.variant) {
    case 'past':
      background = colors.darkBackground;
      break;
    case 'future':
      background = colors.accentBackground;
      break;
    default:
      background = colors.mainBackground;
      break;
  }
  return {
    background,
    boxShadow: `2px 2px 20px ${colors.shadow}`,
    borderRadius: unit * 1.5,
    padding: `${unit * 3}px ${unit * 2}px ${unit * 2.4}px`,
  };
});

const CardTitle = styled('h3')({
  fontWeight: 900,
  fontSize: 20,
  lineHeight: 1.15,
  color: colors.text,
  margin: `0`,
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
});

const CardDate = styled('div')({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: `17px`,
});

const CardResource = styled('h5')({
  display: 'flex',
  alignItems: 'center',
  margin: 'auto 0 0',
  [`@media screen and (max-width: ${breakpointSmall}px)`]: {
    margin: 0,
  },
});

const StyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '& > *:not(:last-child)': {
    marginBottom: unit * 2,
  },
});
