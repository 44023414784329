import React, { useContext } from 'react';
import { PageContainer, EHRAppointmentInfoBlock } from '../components';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { GET_EHR_APPOINTMENT_RESULT } from 'data-layer/queries';
import {
  GetEHRAppointmentResult,
  GetEHRAppointmentResultVariables,
} from 'data-layer/queries/__graphql__/GetEHRAppointmentResult';
import { ClientContext } from 'utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EHRAppointmentsScreenProps extends RouteComponentProps {
  // eslint-disable-next-line react/require-default-props
  appointmentID?: string;
}

const EHRAppointmentResultScreen: React.FC<EHRAppointmentsScreenProps> = ({
  appointmentID = '',
}: EHRAppointmentsScreenProps) => {
  const clientContext = useContext(ClientContext);

  const { t } = useTranslation();
  const appointmentData = useQuery<GetEHRAppointmentResult, GetEHRAppointmentResultVariables>(
    GET_EHR_APPOINTMENT_RESULT,
    {
      variables: {
        businessID: clientContext.businessId,
        clientId: clientContext.clientId,
        visitId: appointmentID,
        user: clientContext.user,
        token: clientContext.token,
      },
      skip: !(
        clientContext.token &&
        clientContext.user &&
        appointmentID &&
        clientContext.clientId &&
        clientContext.businessId
      ),
      fetchPolicy: 'network-only',
    },
  );
  const appData = appointmentData.data?.getEHRAppointmentResult;
  const error = appointmentData.error?.networkError?.message;
  const contentCSS = { alignItems: 'flex-start', padding: 0, overflow: 'hidden' };
  const title = '';
  return (
    <PageContainer title={title} contentCSS={contentCSS} topNav sideNav backLink>
      {appData && <EHRAppointmentInfoBlock appointmentData={appData} />}
      {error && t('screens.ehr.ehrError')}
    </PageContainer>
  );
};

export default EHRAppointmentResultScreen;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */
