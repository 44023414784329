import { gql } from 'apollo-boost';

export const GET_NETWORK_DATA = gql`
  query getNetworkData($networkID: ID!) {
    getNetwork(id: $networkID) @client {
      networkName
      integrationData {
        ehr {
          active
        }
      }
    }
  }
`;
