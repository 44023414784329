import React, { useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import Button from './button';
import { ClientContext } from 'utils';
import { medMeServices } from 'data-layer/helpers';

type ILogoutButtonProps = React.HTMLAttributes<HTMLButtonElement>;

const LogoutButton = ({ children }: ILogoutButtonProps): JSX.Element => {
  const clientContext = useContext(ClientContext);
  const medmeServices = medMeServices({
    ...clientContext,
  });
  const client = useApolloClient();
  const onClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (clientContext.patientId) {
      try {
        medmeServices.authService.removeAuthentication((err) => {
          // callback for error handling
        });
      } catch (error) {}
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.resetStore();
    localStorage.clear();
  };
  return (
    <Button variant="link" data-testid="logout-button" onClick={onClick}>
      {children}
    </Button>
  );
};

LogoutButton.defaultProps = {
  children: undefined,
};

export default LogoutButton;
