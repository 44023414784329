import React, { Component } from 'react';
import styled from '@emotion/styled';

import PageContainer from './pageContainer';
import Heading from './Heading';
import AuthForm from './AuthForm';
import Button from './button';
import { iconType } from './Input';
import CodeInput from './CodeInput';
import { smallText, unit, contentCSS } from '../styles';
import * as RequestSmsCode from '../pages/__graphql__/RequestSmsCode';
import * as ConfirmSmsCode from '../pages/__graphql__/ConfirmSmsCode';
import { withTranslation, WithTranslation } from 'react-i18next';
import { checkPhone } from 'utils/phoneUtils';
import InputAlert from './InputAlert';
import { PhoneInputComponent as PhoneInput } from './PhoneInput';
import { GetBusinessInfo_getBusinessInfo } from 'data-layer/queries/__graphql__/GetBusinessInfo';

export const SMS_CODE_LENGTH = 5;

interface LoginSmsFormProps {
  requestSmsCode: (a: { variables: RequestSmsCode.RequestSmsCodeVariables }) => void;
  confirmSmsCode: (a: { variables: ConfirmSmsCode.ConfirmSmsCodeVariables }) => void;
  switchAuth: () => void;
  phone: string;
  onPhoneChange: (phone: string, country: string) => void;
  smsToken: string;
  businessInfo: GetBusinessInfo_getBusinessInfo;
  country?: string;
  smsSent?: boolean;
  error?: boolean;
  errorRequestCode?: boolean;
  errorConfirmCode?: boolean;
}
interface LoginSmsFormState {
  phone: string;
  country: string;
  phoneValid: boolean;
  errorVisible: boolean;
}

class LoginSmsForm extends Component<LoginSmsFormProps & WithTranslation, LoginSmsFormState> {
  constructor(props: LoginSmsFormProps & WithTranslation, context?: unknown) {
    super(props, context);
    const { phone, error, country = '' } = this.props;
    const phoneValid = !phone.length || checkPhone(phone, country);
    const errorVisible = !!error;
    this.state = { phone, phoneValid, errorVisible, country: country || '' };
  }

  onChangePhone = (phone: string, phoneValid: boolean, countryCode?: string) => {
    const { country } = this.state;
    const { onPhoneChange } = this.props;
    if (countryCode !== country) {
      onPhoneChange(phone, countryCode || '');
    }
    this.setState({ phone, phoneValid, country: countryCode || '', errorVisible: false });
  };

  onSubmit = () => {
    const { phone, country } = this.state;
    const { onPhoneChange, requestSmsCode } = this.props;
    onPhoneChange(phone, country);
    return requestSmsCode({
      variables: {
        phone,
        country,
      },
    });
  };

  onChangeSmsCode = (smsCode: string) => {
    this.setState({ errorVisible: false });
    if (smsCode.length === SMS_CODE_LENGTH) {
      const { confirmSmsCode, smsToken } = this.props;

      confirmSmsCode({
        variables: {
          token: smsToken,
          code: smsCode,
        },
      });
    }
  };

  requestSmsCode() {
    const { phoneValid } = this.state;
    const { smsSent, t } = this.props;
    return (
      <>
        {this.renderPhoneInput()}
        {phoneValid && !smsSent ? (
          <>
            <StyledLabel>{t('components.auth.firstVisit')}</StyledLabel>
            <Button onClick={this.onSubmit}>{t('components.auth.getSmsCode')}</Button>
          </>
        ) : (
          ''
        )}
      </>
    );
  }

  confirmSmsCode() {
    const { errorVisible } = this.state;
    const { t } = this.props;
    return (
      <>
        <StyledLabel>{t('components.auth.smsCode')}</StyledLabel>
        <CodeInputWrapper>
          <CodeInput
            length={SMS_CODE_LENGTH}
            onChange={this.onChangeSmsCode}
            icon={errorVisible ? 'error' : undefined}
          />
          {errorVisible && <InputAlert>{t('components.auth.errorSmsCode')}</InputAlert>}
        </CodeInputWrapper>
        <Button onClick={this.onSubmit}>{t('components.auth.resendSmsCode')}</Button>
      </>
    );
  }

  renderPhoneInput() {
    const { t, businessInfo, country } = this.props;
    const { phone, phoneValid, errorVisible } = this.state;
    let icon;
    if (phone.length) {
      icon = errorVisible || !phoneValid ? 'error' : 'success';
    }
    return (
      <PhoneInput
        phone={phone}
        country={country}
        icon={icon as iconType}
        onPhoneChange={this.onChangePhone}
        businessInfo={businessInfo}
      >
        {!phoneValid && <InputAlert>{t('components.auth.errorPhone')}</InputAlert>}
      </PhoneInput>
    );
  }

  render() {
    const { smsSent, t, switchAuth } = this.props;
    return (
      <PageContainer title={t('title.authTitle')} layoutClassName="login" contentCSS={contentCSS}>
        <StyledHeading>{t('components.auth.phoneNumber')}</StyledHeading>
        <StyledDiv>
          {this.requestSmsCode()}
          {smsSent ? this.confirmSmsCode() : ''}
          <Button variant="link" onClick={switchAuth}>
            {t('components.auth.rememberPasswordButtonLabel')}
          </Button>
        </StyledDiv>
      </PageContainer>
    );
  }
}
export default withTranslation()(LoginSmsForm);
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const StyledHeading = styled(Heading)({
  marginBottom: unit * 3,
});
const StyledLabel = styled('span')(smallText, {
  fontWeight: 500,
});
const StyledDiv = AuthForm.withComponent('div');
const CodeInputWrapper = styled('div')({
  position: 'relative',
});
