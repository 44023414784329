import axios from 'axios';

import { IUpdateUserInfo, IUserInfoResponce } from '../types';
import { createRequest } from './createRequest';
import { MedMeAPI, GBookingCoreV2 } from 'corev2-ts-sdk';
import { urlManager } from 'utils/urlManager';
import { initMedMeSDK } from 'utils';

initMedMeSDK();

export async function findOrCreateClient(
  cred: GBookingCoreV2.Cred,
  businessID: string,
): Promise<GBookingCoreV2.ClientFindOfCreateClientResponseResult> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  if (MedMeAPI.setCredentials) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    MedMeAPI.setCredentials(cred);
  }
  const networkID = urlManager.getNetworkId();
  const params: GBookingCoreV2.ClientFindOrCreateClientRequestParams = {
    business: {
      id: businessID,
    },
  };

  if (networkID) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    params.network = {
      id: networkID,
    };
  }
  const clientData: GBookingCoreV2.ClientFindOfCreateClientResponseResult = await MedMeAPI.client.findOrCreateClient(
    params,
  );
  if (MedMeAPI.clearCredentials) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    MedMeAPI.clearCredentials();
  }
  return clientData;
}

export async function getUserInfo(
  cred: GBookingCoreV2.Cred,
  GBOOKING_COREV2_RPC_URL: string,
): Promise<IUserInfoResponce> {
  return axios
    .post(GBOOKING_COREV2_RPC_URL, createRequest('profile.get_profile_info', {}, cred))
    .then((response) => {
      /*
        eslint-disable
          @typescript-eslint/no-unsafe-assignment,
          @typescript-eslint/no-unsafe-member-access,
          @typescript-eslint/no-unsafe-call,
          @typescript-eslint/no-unsafe-return
      */
      if (response) {
        return response?.data?.result;
        /*
          eslint-enable
            @typescript-eslint/no-unsafe-assignment,
            @typescript-eslint/no-unsafe-member-access,
            @typescript-eslint/no-unsafe-call,
            @typescript-eslint/no-unsafe-return
        */
      }

      return Promise.reject(new Error('common.errors.RPC_ERROR_BODY'));
    }) as Promise<IUserInfoResponce>;
}

export async function updateUserInfo(
  userInfo: IUpdateUserInfo,
  cred: GBookingCoreV2.Cred,
  GBOOKING_COREV2_RPC_URL: string,
): Promise<boolean> {
  const params = {
    name: userInfo.name,
    middleName: userInfo.middleName,
    surname: userInfo.surname,
    birthday: userInfo.birthday,
    gender: userInfo.gender,
    passportId: userInfo.passportId,
  };
  return axios
    .post(GBOOKING_COREV2_RPC_URL, createRequest('profile.update_profile_info', params, cred))
    .then((response) => {
      /*
        eslint-disable
          @typescript-eslint/no-unsafe-assignment,
          @typescript-eslint/no-unsafe-member-access,
          @typescript-eslint/no-unsafe-call,
          @typescript-eslint/no-unsafe-return
      */
      if (response) {
        return response?.data?.result;
        /*
          eslint-enable
            @typescript-eslint/no-unsafe-assignment,
            @typescript-eslint/no-unsafe-member-access,
            @typescript-eslint/no-unsafe-call,
            @typescript-eslint/no-unsafe-return
        */
      }

      return Promise.reject(new Error('common.errors.RPC_ERROR_BODY'));
    }) as Promise<boolean>;
}
