// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import renderHTML from 'react-render-html';

import Input from './Input';
import GlobalSearchFilter from './GlobalSearchFilter';
// import RecentSearchList from './RecentSearchList';
import { breakpointLarge, breakpointMedium, colors, size, unit } from '../styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  GlobalSearch_searchInBusiness,
  GlobalSearchVariables,
  GlobalSearch_searchInBusiness_resources,
  GlobalSearch_searchInBusiness_taxonomies,
  GlobalSearch_searchInBusiness_locations,
} from 'data-layer/queries/__graphql__/GlobalSearch';
import { urlManager } from 'utils/urlManager';
import { getKey, handleOpenWidgetClick, getUniqKey } from 'utils/utils';

const MIN_SEARCH_SYMBOLS = 3;

const inputStyles = css({
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    width: `calc(100% + ${size.sideNavWidth}px)`,
    zIndex: 1,
    [`[dir="ltr"] &`]: {
      transform: `translateX(${unit}px)`,
    },
    [`[dir="rtl"] &`]: {
      transform: `translateX(-${unit}px)`,
    },
  },
});

interface GlobalSearchProps {
  onChange: (a: { variables: GlobalSearchVariables }) => void;
  items?: GlobalSearch_searchInBusiness;
  error?: boolean;
}
interface GlobalSearchState {
  search: string;
  searchType: string | undefined;
}

interface ISearchItemProps {
  id: string;
  type: string;
  text?: string;
  children?: React.ReactNode;
}
class SearchItem extends Component<ISearchItemProps> {
  handleItemClick = () => {
    const { type, id } = this.props;
    handleOpenWidgetClick({ key: id, type });
  };

  render() {
    const { text, children } = this.props;

    return <SearchItemStyled onClick={this.handleItemClick}>{text || children}</SearchItemStyled>;
  }
}

class GlobalSearch extends Component<GlobalSearchProps & WithTranslation, GlobalSearchState> {
  constructor(props: GlobalSearchProps & WithTranslation, context?: unknown) {
    super(props, context);
    this.state = { search: '', searchType: undefined };
  }

  onChangeSearchType = (type: string): void => {
    const { search } = this.state;
    this.setState({ searchType: type });
    this.runSearchRequest(search, type);
  };

  onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = (event.target as HTMLInputElement).value;
    const { searchType } = this.state;
    this.setState({ search });
    this.runSearchRequest(search, searchType);
  };

  // eslint-disable-next-line consistent-return
  runSearchRequest = (search: string, searchType?: string) => {
    const { onChange } = this.props;

    if (search.length >= MIN_SEARCH_SYMBOLS) {
      const searchVariables: GlobalSearchVariables = {
        search,
        businessID: urlManager.getBusinessId(),
        networkID: urlManager.getNetworkId(),
      };
      if (searchType) {
        searchVariables.type = searchType;
      }
      return onChange({
        variables: searchVariables,
      });
    }
  };

  getMarkedText = (str: string | null): React.ReactNode | null => {
    if (str) {
      const { search } = this.state;
      if (search.length >= MIN_SEARCH_SYMBOLS) {
        const regEx = new RegExp(`(${search})`, 'ig');
        const result = str.replace(regEx, '<mark>$1</mark>');
        return renderHTML(result);
      }
      return str;
    }
    return null;
  };

  RenderResource = ({ res }: { res: GlobalSearch_searchInBusiness_resources }): JSX.Element => {
    const { surname, name, middleName, profession } = res;
    const nameStr = [surname, name, middleName].filter((s) => !!s).join(' ');
    return (
      <SearchItem type="resource" id={getKey(res)}>
        <strong>{this.getMarkedText(nameStr)}</strong>
        {this.getMarkedText(profession || '')}
      </SearchItem>
    );
  };

  RenderTaxonomy = ({ tax }: { tax: GlobalSearch_searchInBusiness_taxonomies }): JSX.Element => {
    return (
      <SearchItem type="taxonomy" id={getKey(tax)}>
        <strong>{this.getMarkedText(tax.name)}</strong>
      </SearchItem>
    );
  };

  RenderLocation = ({ loc }: { loc: GlobalSearch_searchInBusiness_locations }): JSX.Element => {
    return (
      <SearchItem type="location" id={loc.internalID}>
        <strong>{this.getMarkedText(loc.general_info.name)}</strong>
        {this.getMarkedText(loc.general_info.address[0].address)}
      </SearchItem>
    );
  };

  renderRecents = () => {
    const { search } = this.state;
    // const { t } = this.props;

    if (search.length >= MIN_SEARCH_SYMBOLS) return null;
    // TODO: recentSearch
    // const resent = [
    //   'Елена Валерьевна, Гинеколог',
    //   'Медлайн на Сухаревской',
    //   'Проктология',
    //   'Гастероскопия',
    // ];
    // const docs = [
    //   'Елена Валерьевна, Гинеколог',
    //   'Агуль Гамаян, Хирург',
    //   'Карл Рихтер, Терапевт',
    //   'Йовин Скальд, Акушер-гинеколог',
    // ];
    return (
      <ResentWrapper>
        {
          // TODO: recentSearch
          /* <RecentSearchList
          title={t('components.search.recentListTitle')}
          type="search"
          items={resent}
        /> */
        }
        {/* <RecentSearchList
          title={t('components.search.doctorListTitle')}
          type="doctor"
          items={docs}
        /> */}
      </ResentWrapper>
    );
  };

  render() {
    const { search } = this.state;
    const { items, t } = this.props;

    return (
      <Container>
        <GlobalSearchFilter onChangeType={this.onChangeSearchType} />
        <StyledInput
          wrapperCSS={inputStyles}
          name="search"
          icon="search"
          placeholder={t('components.search.inputPlaceholder')}
          value={search}
          onChange={this.onChangeSearch}
        />
        <SearchResults>
          {items?.resources?.map((r) => !!r && <this.RenderResource key={getUniqKey(r)} res={r} />)}
          {items?.taxonomies?.map(
            (tx) => !!tx && <this.RenderTaxonomy key={getUniqKey(tx)} tax={tx} />,
          )}
          {items?.locations?.map((l) => !!l && <this.RenderLocation key={l.internalID} loc={l} />)}
          {items?.resources?.length || items?.taxonomies?.length || items?.locations?.length
            ? null
            : this.renderRecents()}
        </SearchResults>
      </Container>
    );
  }
}
export default withTranslation()(GlobalSearch);
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')({
  width: '100%',
  minHeight: '100%',
  paddingTop: unit * 2.7,
  display: 'flex',
  flexDirection: 'column',
  '& > *': {
    flexGrow: 0,
    flexShrink: 0,
  },
});

const SearchItemStyled = styled('div')({
  display: 'block',
  padding: unit,
  color: colors.text,
  fontSize: 14,
  cursor: 'pointer',
  strong: {
    fontWeight: 900,
    fontSize: 20,
    lineHeight: '23px',
    [`[dir="ltr"] &`]: {
      marginRight: '0.3em',
    },
    [`[dir="rtl"] &`]: {
      marginLeft: '0.3em',
    },
  },
  mark: {
    backgroundColor: colors.textMarkBackground,
  },
  '&:hover': {
    textDecoration: 'underline',
  },
});

const SearchResults = styled('div')({
  marginTop: unit * 6.5,
  flexGrow: 1,
  overflow: `auto`,
  maxHeight: `calc(100% - ${unit * 11}px)`,
});

const StyledInput = styled(Input)({
  maxWidth: 'none',
  borderColor: colors.greyOpacity,
  backgroundColor: colors.mainBackground,
  fontSize: 20,
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    fontSize: 16,
    paddingLeft: unit * 1.5,
    paddingRight: unit * 5,
  },
});

const ResentWrapper = styled('div')({
  display: 'flex',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    '& > *:not(:last-child)': {
      marginRight: '9%',
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: unit * 2,
    },
  },
});
