import React from 'react';
import styled from '@emotion/styled';

import { IFilterItem } from './BottomFilter';
import { breakpointLarge, colors, fullSize, listUnstyled, smallText, unit } from '../styles';

interface BottomFilterDropdownProps {
  items: IFilterItem[];
  selectedItems: IFilterItem[];
  onSelectFilter: (values: IFilterItem[]) => void;
}

export const BottomFilterDropdown: React.FC<BottomFilterDropdownProps> = ({
  items,
  selectedItems,
  onSelectFilter,
}: BottomFilterDropdownProps) => {
  return (
    <>
      <BackDrop />
      <Container>
        {items.map(({ key, name, active }: IFilterItem) => {
          const onClick = () => {
            let newSelectedItems = [...selectedItems];
            if (active) {
              newSelectedItems = newSelectedItems.filter((item) => item.key !== key);
            } else {
              newSelectedItems.push({ key, name, active: true });
            }

            return onSelectFilter(newSelectedItems);
          };
          return active ? null : (
            <DropdownItem key={key} onClick={onClick}>
              {name}
            </DropdownItem>
          );
        })}
      </Container>
    </>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const BackDrop = styled('div')(fullSize, {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: -1,
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    position: 'absolute',
    zIndex: 5,
    background: colors.modalBackdropColor,
  },
});

const Container = styled('ul')(listUnstyled, {
  position: 'absolute',
  backgroundColor: colors.mainBackground,
  boxShadow: `2px 2px 20px ${colors.shadow}`,
  borderRadius: unit * 1.5,
  '& > li': {
    margin: unit,
  },
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    bottom: -unit,
    paddingBottom: unit * 2.7,
    minWidth: `calc(100% + ${unit * 2}px)`,
    [`[dir="ltr"] &`]: {
      left: -unit,
    },
    [`[dir="rtl"] &`]: {
      right: -unit,
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    zIndex: 5,
    top: '50%',
    left: '50%',
    right: 'auto',
    transform: 'translate(-50%, -50%)',
    maxHeight: `calc(100% - ${unit * 2}px)`,
    overflow: 'auto',
  },
});

const DropdownItem = styled('li')(smallText, {
  margin: unit,
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  maxWidth: unit * 30,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    fontSize: unit * 2,
    lineHeight: 1.15,
  },
});
