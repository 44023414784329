import { css, SerializedStyles } from '@emotion/core';
import { StylesConfig } from 'react-select';

export const unit = 10;
export const breakpointLarge = 768;
export const breakpointMedium = 590;
export const breakpointSmall = 425;

export const colors = {
  primary: '#220a82',
  secondary: '#47C4E4',
  accent: '#e535ab',
  mainBackground: '#fcfcfc',
  darkBackground: '#f0eef1',
  accentBackground: '#c7ecf8',
  background: '#e6e6e6',
  grey: '#d8d9e0',
  greyOpacity: 'rgba(5, 6, 7, 0.45)',
  text: '#050607',
  textAccent: '#30B7D9',
  textMarkBackground: 'rgba(242, 201, 76, 0.45)',
  textSecondary: '#747790',
  shadow: 'rgba(12, 6, 21, 0.12)',
  modalBackdropColor: 'rgba(255, 255, 255, 0.85)',
};

export const size = {
  layoutMaxWidthDesktop: unit * 108,
  layoutMaxHeightDesktop: unit * 81,
  cardOffset: unit * 2,
  sideNavWidth: unit * 9,
  sideNavHeightMobile: unit * 4.5,
  bottomNavHeight: unit * 7,
};

export const listUnstyled = css({
  listStyle: 'none',
  padding: 0,
  margin: 0,
});

export const flexCenter = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const fullSize = css({
  width: '100%',
  height: '100%',
});

export const hideForMobile = css({
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    display: 'none',
  },
});

export const showForMobile = css({
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    display: 'none',
  },
});

export const largeText = css({
  fontWeight: 900,
  fontSize: unit * 2.6,
  lineHeight: 1.1538461,
  [`@media screen and (max-width: ${breakpointSmall}px)`]: {
    fontSize: unit * 2.4,
  },
});

export const mediumText = css({
  fontSize: unit * 2,
  lineHeight: 1.25,
});

export const smallText = css({
  fontWeight: 900,
  fontSize: 14,
  lineHeight: 1.2142857,
});

export const contentCSS = {
  justifyContent: 'center',
  minHeight: unit * 45,
};

export const sideNavButton = css(flexCenter, {
  border: 'none',
  outline: 'none',
  background: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
});

export const cardBase = css({
  width: `calc(100% / 3 - ${size.cardOffset}px)`,
  marginLeft: size.cardOffset,
  marginBottom: size.cardOffset,
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    width: `calc(100% / 2 - ${size.cardOffset}px)`,
  },
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    width: `calc(100% - ${size.cardOffset}px)`,
  },
});

export const selectStyles: StylesConfig = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    maxWidth: unit * 29.2,
  }),
  control: (provided) => ({
    ...provided,
    padding: `${unit * 0.85}px ${unit * 2.4}px`,
    borderRadius: unit * 10,
    backgroundColor: 'transparent',
    '&, &:hover': {
      borderColor: 'transparent',
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    fontSize: 26,
    lineHeight: 1.153,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: colors.textAccent,
  }),
};

export default (): SerializedStyles => css`
  @font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/roboto-400.woff2') format('woff2'),
      url('/assets/fonts/roboto-400.woff') format('woff'),
      url('/assets/fonts/roboto-400.ttf') format('truetype');
  }

  @font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/roboto-500.woff2') format('woff2'),
      url('/assets/fonts/roboto-500.woff') format('woff'),
      url('/assets/fonts/roboto-500.ttf') format('truetype');
  }

  @font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    src: url('/assets/fonts/roboto-900.woff2') format('woff2'),
      url('/assets/fonts/roboto-900.woff') format('woff'),
      url('/assets/fonts/roboto-900.ttf') format('truetype');
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    height: 100%;
  }

  html {
    box-sizing: border-box;
  }

  body {
    color: ${colors.text};
    margin: 0;
    padding: 0;
    min-width: 320px;
    font-family: Roboto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    background-color: ${colors.background};
  }

  h1,
  h2 {
    margin: 0;
  }

  input {
    outline: none;
  }

  body.ReactModal__Body--open {
    position: fixed;
    overflow: hidden;
    width: 100%;
  }

  #widget-modal {
    display: flex;
    position: relative;
    pointer-events: none;
  }

  #widget-modal > * {
    margin: auto;
    pointer-events: all;
  }

  @media screen and (min-width: ${breakpointMedium + 1}px) {
    #widget-modal {
      margin: auto;
    }
  }

  @media screen and (max-width: ${breakpointMedium}px) {
    #widget-modal {
      margin: 0;
      width: calc(100% - ${unit * 2}px);
      height: calc(100% - ${unit * 2}px);
    }
  }
`;
