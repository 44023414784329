import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link } from '@reach/router';

import SideNavItem from './SideNavItem';
import { paths, navigation } from '../utils/routing';
import { urlManager } from '../utils/urlManager';
import {
  breakpointLarge,
  colors,
  fullSize,
  hideForMobile,
  listUnstyled,
  sideNavButton,
  size,
  unit,
} from '../styles';

import { ReactComponent as IconSearch } from '../assets/icon-search.svg';
// import { ReactComponent as IconCamera } from '../assets/icon-camera.svg';
import { ReactComponent as IconAdd } from '../assets/icon-add.svg';
import { ReactComponent as IconBack } from '../assets/icon-back.svg';
import { ReactComponent as IconFilter } from '../assets/filter.svg';

const queryString = urlManager.getQueryString();

interface ISidenavProps extends React.HTMLAttributes<HTMLDivElement> {
  /* eslint-disable react/require-default-props */
  backLink?: boolean;
  children?: React.ReactNode;
  onBackClick?: () => void;
  onFilterToggle?: () => void;
  /* eslint-enable react/require-default-props */
}

const SideNav: React.FC<ISidenavProps> = ({
  backLink,
  children,
  onBackClick,
  onFilterToggle,
}: ISidenavProps): JSX.Element => {
  let onBackClickHandler = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigation.goBack();
  };
  if (onBackClick) {
    onBackClickHandler = onBackClick;
  }
  return (
    <Container>
      <List>
        <SideNavItem>
          <LinkStyled to={`${paths.searchPath}?${queryString}`}>
            <IconSearch />
          </LinkStyled>
        </SideNavItem>
        {
          // TODO: Telemed widget
          /* <SideNavItem>
          <LinkStyled to={`${paths.homePath}?${queryString}`}>
            <IconCamera />
          </LinkStyled>
        </SideNavItem> */
        }
        <SideNavItem>
          <LinkStyled to={`${paths.newRecordPath}?${queryString}`}>
            <IconAdd />
          </LinkStyled>
        </SideNavItem>
        <>{children}</>
        {backLink && (
          <SideNavItemBack>
            <LinkBack onClick={onBackClickHandler}>
              <IconBack />
            </LinkBack>
          </SideNavItemBack>
        )}
        {onFilterToggle && (
          <SideNavItemFilter>
            <LinkBack onClick={onFilterToggle}>
              <IconFilter />
            </LinkBack>
          </SideNavItemFilter>
        )}
      </List>
    </Container>
  );
};

export default SideNav;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('nav')({
  display: 'flex',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    width: size.sideNavWidth,
    height: '100%',
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    width: '100%',
    height: size.sideNavHeightMobile,
    background: colors.mainBackground,
  },
});

const List = styled('ul')(listUnstyled, fullSize, {
  display: 'flex',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    padding: `${unit * 5.55}px 0 ${unit * 2.75}px`,
    flexDirection: 'column',
    marginRight: unit,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    justifyContent: 'space-around',
  },
});

const linkStyles = css(sideNavButton, fullSize, {
  border: 'none',
  '&:hover': {
    backgroundColor: colors.accentBackground,
  },
});

const LinkStyled = styled(Link)(linkStyles);

const LinkBack = styled('button')(linkStyles);

const SideNavItemBack = styled(SideNavItem)({
  [`[dir="rtl"] & svg`]: {
    transform: 'rotate(180deg)',
  },
  '.home &': {
    display: 'none',
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    '.filters &': {
      display: 'block',
    },
  },
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    marginTop: 'auto',
  },
});

const SideNavItemFilter = styled(SideNavItem)(hideForMobile, {
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    marginTop: 'auto',
  },
});
