import React, { useContext } from 'react';
import { PageContainer, EHRDiagnosticInfoBlock } from '../components';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import {
  GetEHRDiagnosticResult,
  GetEHRDiagnosticResultVariables,
} from 'data-layer/queries/__graphql__/GetEHRDiagnosticResult';
import { ClientContext } from 'utils';
import { GET_EHR_DIAGNOSTIC_RESULT } from 'data-layer/queries/getEHRDiagnosticResult';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EHRDiagnosticsScreenProps extends RouteComponentProps {
  // eslint-disable-next-line react/require-default-props
  appointmentID?: string;
}

const EHRDiagnosticResultScreen: React.FC<EHRDiagnosticsScreenProps> = ({
  appointmentID = '',
}: EHRDiagnosticsScreenProps) => {
  const clientContext = useContext(ClientContext);

  const { t } = useTranslation();
  const appointmentData = useQuery<GetEHRDiagnosticResult, GetEHRDiagnosticResultVariables>(
    GET_EHR_DIAGNOSTIC_RESULT,
    {
      variables: {
        visitId: appointmentID,
      },
      skip: !(
        clientContext.token &&
        clientContext.user &&
        appointmentID &&
        clientContext.clientId &&
        clientContext.businessId
      ),
      fetchPolicy: 'cache-only',
    },
  );
  const appData = appointmentData.data?.getEHRDiagnosticResult;
  const error = appointmentData.error?.networkError?.message;
  const contentCSS = { alignItems: 'flex-start', padding: 0, overflow: 'hidden' };
  const title = '';
  return (
    <PageContainer title={title} contentCSS={contentCSS} topNav sideNav backLink>
      {appData && <EHRDiagnosticInfoBlock appointmentData={appData} />}
      {error && t('screens.ehr.ehrError')}
    </PageContainer>
  );
};

export default EHRDiagnosticResultScreen;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */
