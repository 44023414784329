import styled from '@emotion/styled';
import { breakpointLarge, colors, size } from '../styles';

const Layout = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  position: 'relative',
  maxWidth: size.layoutMaxWidthDesktop,
  width: '100vw',
  height: '100vh',
  backgroundColor: colors.mainBackground,
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    maxHeight: size.layoutMaxHeightDesktop,
    borderTopWidth: 4,
    borderTopStyle: 'solid',
    borderTopColor: colors.secondary,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    maxHeight: 'none',
  },
});

export default Layout;
