/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { EHR } from 'medme-ehr-js-sdk';
import { config, getPhoneString, getUserCombineInfo, constants } from 'utils';
import { AppointmentService } from 'medme-ehr-js-sdk/dist/es5/services/jsonRPC/AppointmentService';
import { AppointmentResultService } from 'medme-ehr-js-sdk/dist/es5/services/jsonRPC/AppointmentResultService';
import { PrescriptionService } from 'medme-ehr-js-sdk/dist/es5/services/jsonRPC/PrescriptionService';
import { DiagnosticReportService } from 'medme-ehr-js-sdk/dist/es5/services/jsonRPC/DiagnosticReportService';
import { PatientService } from 'medme-ehr-js-sdk/dist/es5/services/jsonRPC/PatientService';
import { AuthService } from 'medme-ehr-js-sdk/dist/es5/services/jsonRPC/AuthService';
import {
  IJsonRPCRequest,
  IJsonRpcHeader,
  IJsonRpcResponseCallback,
} from 'medme-ehr-js-sdk/dist/es5/services/jsonRPC/jsonRpcRequest';
import { ConnectionError } from 'medme-ehr-js-sdk/dist/es5/services/AuthService';
import { requestCred } from 'medme-ehr-js-sdk/dist/es5/services/jsonRPC/jsonrpc_cred';
import { GetUserInfo_getUserInfo } from 'data-layer/queries/__graphql__/GetUserInfo';
import { GetClientData_findOrCreateClient } from 'data-layer/queries/__graphql__/GetClientData';
import moment from 'moment';
import { ICred } from 'data-layer/types/ICred';
import { Gender, SexEHR } from 'data-layer/types';
/*
        eslint-disable
            consistent-return,
            @typescript-eslint/no-explicit-any
*/
const xhr: IJsonRPCRequest = (
  endpoint: string,
  header: IJsonRpcHeader,
  // eslint-disable-next-line @typescript-eslint/ban-types
  requestPayload: object,
  cb?: IJsonRpcResponseCallback,
) => {
  const req = new XMLHttpRequest();
  req.responseType = 'json';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  req.onload = (res: any) => {
    const target: XMLHttpRequest = res.target;
    if (target.status >= 400) {
      if (cb) {
        cb(new Error(`status code ${target.status}`));
      }
    } else if (target.response) {
      const jsonRpcResponse = target.response;
      if (jsonRpcResponse.result) {
        if (cb) {
          cb(undefined, jsonRpcResponse.result);
        }
      } else if (jsonRpcResponse.error) {
        if (cb) {
          cb(jsonRpcResponse.error);
        }
      } else if (cb) {
        cb(new Error(`wrong json-rpc format ${jsonRpcResponse as string}`));
      }
    } else if (cb) {
      cb(new Error('wrong json format'));
    }
  };

  req.onerror = (res: any) => {
    const target: XMLHttpRequest = res.target;
    if (target.status === 0 && cb) {
      return cb(new ConnectionError(), undefined);
    }
    if (cb) {
      cb(new Error(`error request ${endpoint} method #' ${header.method}`), undefined);
    }
  };
  req.open('POST', endpoint, true);
  // req.overrideMimeType('application/json;charset=UTF-8');
  req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  const jsonRpcRequest = requestCred(header.id, header.method, header.cred, requestPayload);
  if (process.env.REACT_APP_DEVELOP) {
    // eslint-disable-next-line no-console
    console.log('jsonRpcRequest.serialize()', jsonRpcRequest.serialize());
  }
  req.send(jsonRpcRequest.serialize());
};
/*
    eslint-enable
        consistent-return,
        @typescript-eslint/no-explicit-any
*/
export interface IMedMeServicesProps {
  user: string;
  token: string;
  businessId: string;
  clientId: string;
}

export interface PatientProperties {
  medCardId?: string;
  phone: string;
  id: string;
  surname: string;
  name: string;
  middleName: string;
  email: string;
  date: Date;
  gender: SexEHR;
  passportId: string;
}

export const getPatientInfoFromProfile = (
  profile?: GetUserInfo_getUserInfo,
  client?: GetClientData_findOrCreateClient,
): PatientProperties => {
  if (!profile || !client) {
    return {} as PatientProperties;
  }
  const userInfo = getUserCombineInfo(profile, client);
  let gender = SexEHR.MALE;
  if (userInfo.gender === Gender.female) gender = SexEHR.FEMALE;

  const birthdayDate = moment
    .utc(userInfo.birthday || moment.utc(), constants.DATE_FORMAT)
    .toDate();
  const patientInfo = {
    phone: getPhoneString(profile.phone[0]),
    email: profile.email?.[0] || client.email?.[0] || '',
    id: client.id,
    surname: userInfo.surname,
    name: userInfo.name,
    middleName: userInfo.middleName,
    date: birthdayDate,
    gender,
    medCardId: profile.medCardId || undefined,
    passportId: profile.passportId || client.passportId || '',
  };

  return {
    ...patientInfo,
  };
};

export interface IMedMeServices {
  appointmentService: AppointmentService;
  appointmentResultService: AppointmentResultService;
  prescriptionService: PrescriptionService;
  diagnosticReportService: DiagnosticReportService;
  patientService: PatientService;
  authService: AuthService;
  env: {
    PATIENT_ID: string | null;
    client: string;
  };
}
export const medMeServices = (props: IMedMeServicesProps): IMedMeServices => {
  // Initialize API services
  const { JsonRPC } = EHR.Services;
  const cred: ICred = { user: props.user, token: props.token };
  const { clientId, businessId } = props;
  const env = {
    businessId,
    clientId,
    ehrEndpoint: config.REACT_APP_EHR_URL,
    authEndpoint: config.REACT_APP_GBOOKING_COREV2_RPC_URL,
    exchangeTokenMethod: 'client.get_exchange_token',
  };

  const appointmentService = new JsonRPC.AppointmentService(env.ehrEndpoint, cred, xhr);
  const appointmentResultService = new JsonRPC.AppointmentResultService(env.ehrEndpoint, cred, xhr);
  const prescriptionService = new JsonRPC.PrescriptionService(env.ehrEndpoint, cred, xhr);
  const diagnosticReportService = new JsonRPC.DiagnosticReportService(env.ehrEndpoint, cred, xhr);
  const patientService = new JsonRPC.PatientService(env.ehrEndpoint, cred, xhr);
  const authService = new JsonRPC.AuthService(
    env.ehrEndpoint,
    env.authEndpoint,
    cred,
    xhr,
    env.exchangeTokenMethod,
    {
      business: {
        id: env.businessId,
      },
      client: {
        id: env.clientId,
      },
    },
  );

  return {
    appointmentService,
    appointmentResultService,
    prescriptionService,
    diagnosticReportService,
    patientService,
    authService,
    env: {
      PATIENT_ID: null, // it should be filled after authorization (see medme-app)
      client: env.clientId,
    },
  };
};

export const DUMP = {};
