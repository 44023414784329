import { gql } from 'apollo-boost';

export const GET_BUSINESS = gql`
  query getBusiness($id: ID!) {
    getBusinessByID(id: $id) @client {
      id
      info {
        general_info {
          networkID
          logo_url
          name
          shortName
          address {
            country
            address
            latitude
            longitude
          }
          description
          phone {
            country_code
            area_code
            number
          }
          timezone
        }
        backoffice_configuration {
          enableExtendedPhone
          enablePhoneNationalMode
        }
        widget_configuration {
          requireAgreement
          requireAgreementLink
        }
      }
      integrationData {
        ehr {
          active
        }
      }
      resources {
        name
        surname
        middleName
        id
        extraId
        siteId
      }
      taxonomies {
        name
        id
        extraId
        siteId
      }
      top_services {
        status
        services
      }
    }
  }
`;
