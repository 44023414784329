import axios from 'axios';

import { IProfileResponse, IResponse } from '../types';
import { createRequest } from './createRequest';
import { MedMeAPI, GBookingCoreV2 } from 'corev2-ts-sdk';
import { initMedMeSDK } from 'utils';

initMedMeSDK();

interface BusinessListObject {
  use_optimized_cache: boolean;
  use_raw_data?: boolean;
  business: { list: string[] };
  resource?: { id: string };
  taxonomy?: { id: string };
}

export function createBusinessListObject(
  businessList: string[],
  resourceId?: string,
  taxonomyId?: string,
): BusinessListObject {
  const params: BusinessListObject = {
    use_optimized_cache: true,
    use_raw_data: true,
    business: {
      list: businessList,
    },
  };

  if (resourceId && resourceId !== 'UNAVAILABLE') {
    params.use_raw_data = undefined;
    params.resource = {
      id: resourceId,
    };
  }
  if (taxonomyId) {
    params.use_raw_data = undefined;
    params.taxonomy = {
      id: taxonomyId,
    };
  }

  return params;
}

interface GetBusinessListParams {
  businessList: string[];
  resourceId?: string;
  taxonomyId?: string;
  GBOOKING_COREV2_RPC_URL: string;
}

async function getBusinessList({
  businessList,
  resourceId,
  taxonomyId,
  GBOOKING_COREV2_RPC_URL,
}: GetBusinessListParams): Promise<IResponse<IProfileResponse[]>> {
  return axios
    .post(
      GBOOKING_COREV2_RPC_URL,
      createRequest(
        'business.get_profiles_list',
        createBusinessListObject(businessList, resourceId, taxonomyId),
      ),
    )
    .then((response) => {
      /*
        eslint-disable
          @typescript-eslint/no-unsafe-assignment,
          @typescript-eslint/no-unsafe-member-access,
          @typescript-eslint/no-unsafe-call,
          @typescript-eslint/no-unsafe-return
      */
      if (response) {
        try {
          const parsed = response.data.result.map((b: string) => JSON.parse(b).result);
          response.data.result = parsed;
        } catch (e) {
          // ignore
        }

        return response.data;
        /*
          eslint-enable
            @typescript-eslint/no-unsafe-assignment,
            @typescript-eslint/no-unsafe-member-access,
            @typescript-eslint/no-unsafe-call,
            @typescript-eslint/no-unsafe-return
        */
      }

      return Promise.reject(new Error('common.errors.RPC_ERROR_BODY'));
    }) as Promise<IResponse<IProfileResponse[]>>;
}

type FetchBusinessParams = {
  businessId: string;
  taxonomyId?: string;
  resourceId?: string;
  GBOOKING_COREV2_RPC_URL: string;
};

export async function fetchBusiness(params: FetchBusinessParams): Promise<IProfileResponse> {
  const res = await getBusinessList({
    ...params,
    businessList: [params.businessId],
  });

  return res.result[0];
}

export async function getNetwork(
  id: number,
): Promise<GBookingCoreV2.BusinessGetNetworkDataResponse> {
  const params: GBookingCoreV2.BusinessGetNetworkDataRequestParams = {
    networkID: +id,
  };
  return MedMeAPI.business.getNetworkData(params);
}

export async function getNetworkBusinessList(
  id: number,
): Promise<GBookingCoreV2.BusinessRefInNetwork[]> {
  return MedMeAPI.business.getNetworkBusinessList(+id);
}

export async function getBusinessByID(
  id: string,
): Promise<GBookingCoreV2.BusinessGetProfileByIdResponseResult | undefined> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const params: GBookingCoreV2.BusinessGetProfileByIdRequestParams = {
    business: {
      id,
    },
    use_optimized_cache: true,
  };
  const requestResult = await MedMeAPI.business.getProfileById(params);

  return requestResult?.result;
}
