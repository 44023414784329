/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { CSSProperties, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { navigate } from '@reach/router';
import Layout from './Layout';
import Header from './header';
import TopNav from './topNav';
import SideNav from './SideNav';
import SideNavItem from './SideNavItem';
import SideNavFilters from './SideFilters';
import SideFilterButton from './SideFilterButton';
import { breakpointLarge, breakpointSmall, hideForMobile, size, unit } from '../styles';
import { urlManager } from 'utils/urlManager';
import {
  getNetworkBranchListVariables,
  getNetworkBranchList,
  getNetworkBranchList_getNetworkBusinessList_businesses,
} from 'data-layer/queries/__graphql__/getNetworkBranchList';
import { getBusiness, getBusinessVariables } from 'data-layer/queries/__graphql__/getBusiness';
import { GET_NETWORK_BRANCH_LIST, GET_BUSINESS } from 'data-layer/queries';
import { IAppFilter, IEHRCounterType } from 'data-layer/types';

import { ReactComponent as IconEMK } from '../assets/icon-emk.svg';
import { ClientContext } from 'utils';
import { paths } from 'utils/routing';

const logoUrlDefault = 'https://med.me/img/logo.svg';
let logoUrl = logoUrlDefault;

interface IPageContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  layoutClassName?: string;
  contentCSS?: CSSProperties | undefined;
  title?: string;
  showAppFilter?: boolean;
  sideFilterButton?: boolean;
  appFilter?: IAppFilter;
  filterChange?: (key: IAppFilter) => void;
  topNav?: boolean;
  bottomNav?: boolean;
  sideNav?: boolean;
  backLink?: boolean;
  children?: React.ReactNode;
  bottomFilter?: React.ReactNode;
  ehrAppTypeFilter?: IEHRCounterType;
  setBottomNav?: (a: boolean) => void;
  setEhrAppTypeFilter?: (a: IEHRCounterType) => void;
  withTelemed?: boolean;
  onBackClick?: () => void;
}

export default function PageContainer({
  className = undefined,
  layoutClassName = undefined,
  children = undefined,
  title = '',
  topNav = false,
  sideNav = false,
  backLink = false,
  contentCSS = undefined,
  showAppFilter = false,
  sideFilterButton = false,
  filterChange = undefined,
  appFilter = undefined,
  ehrAppTypeFilter = undefined,
  setEhrAppTypeFilter = undefined,
  withTelemed = false,
  bottomFilter = undefined,
  bottomNav = false,
  setBottomNav = () => null,
  onBackClick = undefined,
}: IPageContainerProps): JSX.Element {
  const networkID = urlManager.getNetworkId();
  const businessID = urlManager.getBusinessId();
  const onFilterToggle = () => setBottomNav(!bottomNav);
  const clientContext = useContext(ClientContext);
  const { ehrCounter, patientId, ehrEnabled } = clientContext;
  const networkData = useQuery<getNetworkBranchList, getNetworkBranchListVariables>(
    GET_NETWORK_BRANCH_LIST,
    {
      variables: { networkID },
      fetchPolicy: 'cache-and-network',
      skip: !networkID,
    },
  );
  const businessData = useQuery<getBusiness, getBusinessVariables>(GET_BUSINESS, {
    variables: {
      id: businessID,
    },
    skip: !businessID,
  });

  if (networkID) {
    const businesses = networkData.data?.getNetworkBusinessList?.businesses;

    const businessWithLogo = (businesses || []).find(
      (b: getNetworkBranchList_getNetworkBusinessList_businesses | null) =>
        !!b?.info?.general_info?.logo_url,
    );
    if (businessWithLogo) {
      logoUrl = businessWithLogo.info.general_info.logo_url || '';
    }
  } else if (businessID) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    logoUrl = businessData.data?.getBusinessByID?.info.general_info?.logo_url || '';
  }

  const titleText = title || '';

  const queryString = urlManager.getQueryString();

  return (
    <Layout className={layoutClassName}>
      <Header image={logoUrl} title={titleText}>
        {topNav && (
          <TopNav
            showAppFilter={showAppFilter}
            filterChange={filterChange}
            appFilter={appFilter}
            withTelemed={withTelemed}
          />
        )}
      </Header>
      <Container className={className}>
        <Content style={contentCSS} sideNav={sideNav} bottomNav={bottomNav}>
          {children}
        </Content>
        {bottomNav && bottomFilter ? bottomFilter : ''}
        {sideNav && (
          <Sidebar>
            <SideNav
              backLink={backLink}
              onBackClick={onBackClick}
              onFilterToggle={sideFilterButton ? onFilterToggle : undefined}
            >
              {!patientId && ehrEnabled && (
                <SideNavItemMiddle>
                  <SideFilterButton onClick={() => navigate(`${paths.ehrPath}?${queryString}`)}>
                    <IconEMK />
                  </SideFilterButton>
                </SideNavItemMiddle>
              )}
              {patientId && setEhrAppTypeFilter && (
                <SideNavFilters
                  counts={ehrCounter}
                  active={ehrAppTypeFilter}
                  setActive={setEhrAppTypeFilter}
                />
              )}
            </SideNav>
          </Sidebar>
        )}
      </Container>
    </Layout>
  );
}

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

interface IContentProps {
  sideNav?: boolean;
  bottomNav?: boolean;
}

const columnStyle = css({
  position: 'absolute',
  top: 0,
  bottom: 0,
});

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  flexGrow: 1,
});

const Content = styled('div')(columnStyle, ({ sideNav, bottomNav }: IContentProps) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    padding: `0 ${unit * 3}px ${unit * 6}px`,
    overflow: 'auto',
    [`[dir="ltr"] &`]: {
      left: 0,
    },
    [`[dir="rtl"] &`]: {
      right: 0,
    },
    [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
      marginBottom: bottomNav ? size.bottomNavHeight : 0,
      [`[dir="ltr"] &`]: {
        right: sideNav ? size.sideNavWidth : 0,
      },
      [`[dir="rtl"] &`]: {
        left: sideNav ? size.sideNavWidth : 0,
      },
    },
    [`@media screen and (max-width: ${breakpointLarge}px)`]: {
      bottom: size.sideNavHeightMobile,
      [`[dir="ltr"] &`]: {
        right: 0,
      },
      [`[dir="rtl"] &`]: {
        left: 0,
      },
    },
    [`@media screen and (max-width: ${breakpointSmall}px)`]: {
      paddingLeft: unit * 2,
      paddingRight: unit * 2,
    },
    [`@media screen and (max-height: ${unit * 58}px)`]: {
      overflow: 'visible',
    },
  };
});

const Sidebar = styled('div')(columnStyle, {
  [`[dir="ltr"] &`]: {
    right: 0,
  },
  [`[dir="rtl"] &`]: {
    left: 0,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    [`[dir="ltr"] &`]: {
      left: 0,
    },
    [`[dir="rtl"] &`]: {
      right: 0,
    },
  },
});

const SideNavItemMiddle = styled(SideNavItem)(hideForMobile, {
  '&, & > *': {
    margin: 'auto',
  },
});
