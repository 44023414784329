import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { ReactComponent as IconFilter } from '../assets/filter.svg';
import { colors, hideForMobile, smallText, unit } from '../styles';

interface IGlobalSearchFilterProps {
  onChangeType: (type: string) => unknown;
}

const GlobalSearchFilter: React.FC<IGlobalSearchFilterProps> = (
  props: IGlobalSearchFilterProps,
) => {
  const { onChangeType } = props;
  const { t } = useTranslation();
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [activeFilter, setActiveFilter] = useState('all');

  const renderItems = () => {
    if (!filtersVisible) return null;
    return ['all', 'locations', 'resources', 'taxonomies'].map((s) => (
      <FilterItemStyled
        key={s}
        active={s === activeFilter}
        onClick={() => {
          setActiveFilter(s);
          onChangeType(s);
        }}
      >
        {t(`components.search.filters.${s}`)}
      </FilterItemStyled>
    ));
  };

  return (
    <Container>
      <Item onClick={() => setFiltersVisible(!filtersVisible)}>
        <IconFilter />
        {t('components.search.filter')}
      </Item>
      {renderItems()}
    </Container>
  );
};

export default GlobalSearchFilter;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')(hideForMobile, {
  display: 'flex',
  padding: `${unit * 1.3}px ${unit * 2.4}px`,
});

const Item = styled('div')(smallText, {
  display: 'flex',
  alignItems: 'flex-end',
  color: colors.textAccent,
  cursor: 'pointer',
});

interface FilterItemStyledProps extends React.HTMLAttributes<HTMLDivElement> {
  active: boolean;
}

const FilterItemStyled = styled(Item)(({ active }: FilterItemStyledProps) => ({
  color: active ? '#000' : colors.textAccent,
  [`[dir="ltr"] &`]: {
    marginLeft: unit * 5.4,
  },
  [`[dir="rtl"] &`]: {
    marginRight: unit * 5.4,
  },
}));
