import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql, ApolloError } from 'apollo-boost';
import { navigate } from '@reach/router';
import { LoginPasswordForm, LoginSmsForm, Loading, PageContainer } from '../components';
import * as LoginTypes from './__graphql__/Login';
import * as RequestSmsCode from './__graphql__/RequestSmsCode';
import * as ConfirmSmsCode from './__graphql__/ConfirmSmsCode';
import { paths } from 'utils/routing';
import { ClientContext, urlManager } from 'utils';
import {
  GetBusinessInfo,
  GetBusinessInfoVariables,
} from 'data-layer/queries/__graphql__/GetBusinessInfo';
import { GET_BUSINESS_INFO, USER_PHONE } from 'data-layer/queries';
import {
  SetPhoneCountry,
  SetPhoneCountryVariables,
} from 'data-layer/mutations/__graphql__/SetPhoneCountry';
import { SET_PHONE_COUNTRY } from 'data-layer/mutations/PhoneCountry';
import { UserPhone } from 'data-layer/queries/__graphql__/UserPhone';
import { useTranslation } from 'react-i18next';
import { contentCSS } from 'styles';

export const LOGIN_USER = gql`
  mutation Login($phone: String!, $password: String!, $country: String!) {
    login(phone: $phone, password: $password, country: $country) @client {
      token
      user
      expires
      askNewPass
    }
  }
`;

export const REQUEST_SMS_CODE = gql`
  mutation RequestSmsCode($phone: String!, $country: String!) {
    requestSmsCode(phone: $phone, country: $country) @client {
      token
    }
  }
`;

export const CONFIRM_SMS_CODE = gql`
  mutation ConfirmSmsCode($token: String!, $code: String!) {
    confirmSmsCode(token: $token, code: $code) @client {
      token
      user
      expires
      askNewPass
    }
  }
`;

export default function Login(): JSX.Element {
  const { t } = useTranslation();
  const [showSmsAuth, switchAuth] = useState(false);
  const [smsToken, setSmsToken] = useState('');
  const [phoneString, phoneStringInput] = useState('');
  const [country, setCountry] = useState('');
  const clientContext = useContext(ClientContext);
  const phoneData = useQuery<UserPhone>(USER_PHONE);
  if (phoneData.data?.phoneCountry && phoneData.data?.phoneCountry !== country) {
    setCountry(phoneData.data?.phoneCountry);
  }
  const [login, { loading, error: loginError }] = useMutation<
    LoginTypes.Login,
    LoginTypes.LoginVariables
  >(LOGIN_USER, {
    onError(err: ApolloError) {
      // eslint-disable-next-line no-console
      console.log('%s', err.message);
    },
    onCompleted(data) {
      const queryString = urlManager.getQueryString();
      if (!data.login?.askNewPass) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(`${paths.homePath}?${queryString}`);
      }
    },
  });
  const [setPhoneCountry] = useMutation<SetPhoneCountry, SetPhoneCountryVariables>(
    SET_PHONE_COUNTRY,
  );
  const [confirmSmsCode, ConfirmSmsCodeStatus] = useMutation<
    ConfirmSmsCode.ConfirmSmsCode,
    ConfirmSmsCode.ConfirmSmsCodeVariables
  >(CONFIRM_SMS_CODE, {
    onError(err: ApolloError) {
      // eslint-disable-next-line no-console
      console.log('%s', err.message);
    },
  });

  const [requestSmsCode, RequestSmsCodeStatus] = useMutation<
    RequestSmsCode.RequestSmsCode,
    RequestSmsCode.RequestSmsCodeVariables
  >(REQUEST_SMS_CODE, {
    // eslint-disable-next-line no-shadow
    onCompleted({ requestSmsCode }) {
      if (requestSmsCode && requestSmsCode.token) {
        // save token and code to state
        setSmsToken(requestSmsCode.token);
      }
    },
    onError(err: ApolloError) {
      // eslint-disable-next-line no-console
      console.log('%s', err.message);
    },
  });
  const smsCodeError = RequestSmsCodeStatus.error || ConfirmSmsCodeStatus.error;

  const switchAuthToggle = () => {
    delete ConfirmSmsCodeStatus.error;
    delete RequestSmsCodeStatus.error;
    switchAuth(!showSmsAuth);
  };
  const phoneStringChange = (phone: string, phoneCountry?: string) => {
    phoneStringInput(phone);
    if (phoneCountry && phoneCountry !== country) {
      setCountry(phoneCountry);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setPhoneCountry({ variables: { country: phoneCountry } });
    }
  };

  const generalInfoData = useQuery<GetBusinessInfo, GetBusinessInfoVariables>(GET_BUSINESS_INFO, {
    variables: {
      ID: clientContext.businessId,
    },
    skip: !clientContext.businessId,
  });
  const businessInfo = generalInfoData?.data?.getBusinessInfo;
  if (loading || RequestSmsCodeStatus.loading || ConfirmSmsCodeStatus.loading || !businessInfo) {
    return (
      <PageContainer title={t('title.authTitle')} layoutClassName="login" contentCSS={contentCSS}>
        <Loading />
      </PageContainer>
    );
  }

  return showSmsAuth ? (
    <LoginSmsForm
      requestSmsCode={requestSmsCode}
      confirmSmsCode={confirmSmsCode}
      phone={phoneString}
      smsSent={!!smsToken}
      smsToken={smsToken}
      onPhoneChange={phoneStringChange}
      switchAuth={switchAuthToggle}
      error={smsCodeError ? true : undefined}
      businessInfo={businessInfo}
      country={country}
    />
  ) : (
    <LoginPasswordForm
      login={login}
      phone={phoneString}
      country={country}
      onPhoneChange={phoneStringChange}
      switchAuth={switchAuthToggle}
      error={loginError ? true : undefined}
      businessInfo={businessInfo}
    />
  );
}
