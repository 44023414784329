import styled from '@emotion/styled';
import { breakpointLarge, flexCenter, unit } from '../styles';

const SideNavItem = styled('li')(flexCenter, {
  width: unit * 5,
  height: unit * 5,
  borderRadius: '50%',
  overflow: 'hidden',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    margin: `${unit * 2.25}px auto`,
  },
});

export default SideNavItem;
