/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';

import UserPic from './UserPic';
import {
  unit,
  colors,
  listUnstyled,
  smallText,
  breakpointLarge,
  breakpointMedium,
  breakpointSmall,
} from '../styles';
import { urlManager } from 'utils/urlManager';
import { paths } from '../utils/routing';
import { IAppFilter } from 'data-layer/types';
import { TopNavAppFilter } from './TopNavAppFilter';

const queryString = urlManager.getQueryString();

interface ITopNavProps extends React.HTMLAttributes<HTMLDivElement> {
  showAppFilter?: boolean;
  appFilter?: IAppFilter;
  filterChange?: (filter: IAppFilter) => void;
  withTelemed?: boolean;
}
const TopNav: React.FC<ITopNavProps> = ({
  showAppFilter = false,
  appFilter = IAppFilter.all,
  filterChange = undefined,
  withTelemed = false,
}: ITopNavProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Nav>
      <ListOuter>
        {showAppFilter && (
          <TopNavAppFilter
            selectedFilter={appFilter}
            filterChange={filterChange}
            withTelemed={withTelemed}
          />
        )}
        <ItemRoot>
          <LinkStyled to={`${paths.newRecordPath}?${queryString}`}>
            {t('components.topNav.newRecord')}
          </LinkStyled>
        </ItemRoot>
        <ItemRoot>
          <LinkUserStyled to={`${paths.profileInfoPath}?${queryString}`}>
            <UserPicStyled size={60} />
          </LinkUserStyled>
        </ItemRoot>
      </ListOuter>
    </Nav>
  );
};

export default TopNav;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Nav = styled('nav')({
  padding: `0 ${unit * 2}px`,
  position: 'relative',
  zIndex: 1,
  flexShrink: 0,
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    width: '100%',
    padding: 0,
  },
});

const ListOuter = styled('ul')(listUnstyled, {
  display: 'flex',
  alignItems: 'center',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    '& > *:not(:last-child)': {
      [`[dir="ltr"] &`]: {
        marginRight: unit * 5,
      },
      [`[dir="rtl"] &`]: {
        marginLeft: unit * 5,
      },
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    justifyContent: 'space-between',
    height: unit * 4,
  },
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: unit * 2,
      '.home &:first-of-type': {
        [`[dir="ltr"] &`]: {
          marginLeft: 0,
          marginRight: 'auto',
        },
        [`[dir="rtl"] &`]: {
          marginRight: 0,
          marginLeft: 'auto',
        },
      },
    },
  },
  [`@media screen and (max-width: ${breakpointSmall}px)`]: {
    '& > *': {
      marginLeft: unit,
      '& > a': {
        whiteSpace: 'nowrap',
      },
    },
  },
});

const Label = styled('span')(smallText, {
  color: colors.textAccent,
  cursor: 'pointer',
  textDecoration: 'none',
});
const LinkStyled = Label.withComponent(Link);

const LinkUserStyled = styled(Link)({
  display: 'block',
  borderRadius: '50%',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    boxShadow: `0 0 0 ${unit * 0.2}px ${colors.secondary}`,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    '.profile &': {
      display: 'none',
    },
  },
});

const UserPicStyled = styled(UserPic)({
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    width: 40,
    height: 40,
  },
});

const ItemRoot = styled('li')({
  position: 'relative',
  '& > span': {
    position: 'relative',
    zIndex: 1,
  },
});
