/* eslint-disable react/require-default-props */
import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { unit } from '../styles';
import ProfileForm from 'components/ProfileForm';
import * as IUserInfo from 'data-layer/mutations/__graphql__/UpdateUserInfo';
import { useTranslation } from 'react-i18next';
import { medMeServices, PatientProperties } from 'data-layer/helpers';
import { EHR } from 'medme-ehr-js-sdk';
import { PatientInputProperties } from 'medme-ehr-js-sdk/dist/es5/types';
import { SetPatientIdVariables } from 'data-layer/mutations/__graphql__/SetPatientId';
import { AuthenticateInputResultCallback } from 'medme-ehr-js-sdk/dist/es5/services/AuthService';
import { config, constants, ClientContext } from 'utils';
import { ICred } from 'data-layer/types/ICred';

interface EhrLoginProps {
  setPatientData: (a: { variables: SetPatientIdVariables }) => void;
}

export const EhrLogin: React.FC<EhrLoginProps> = ({ setPatientData }: EhrLoginProps) => {
  let ehrSubmitHandler: AuthenticateInputResultCallback;
  const [ehrError, setEhrError] = useState('');
  const { t } = useTranslation();
  const clientContext = useContext(ClientContext);
  const { businessId, user, token, clientInfo } = clientContext;
  const cred: ICred = {
    user,
    token,
  };
  const medmeServices = medMeServices({
    businessId,
    clientId: clientInfo.id,
    user,
    token,
  });
  const authPatient = async () => {
    return new Promise((resolve) => {
      EHR.Services.getAuthenticatedPatient(
        medmeServices.patientService,
        medmeServices.authService,
        function getPatientInput(next) {
          ehrSubmitHandler = next;
          resolve();
        },
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        function authenticatedPatient(err, data) {
          // Обрабатываем ошибки авторизации
          const { PatientAuthenticationError } = EHR.Services;
          // Ошибка - сервер ЭМК недоступен.
          // Проверку на ошибку недоступности ЭМК необходимо производить перед
          // проверкой на ошибку соединения сети.
          if (err && PatientAuthenticationError.isEhrServerDisabled(err)) {
            setEhrError(t('screens.ehr.ehrDisabled'));

            return false;
          }

          // Ошибка соединения сети
          if (err && PatientAuthenticationError.isConnectionError(err)) {
            setEhrError(t('screens.ehr.ehrConnectionFailed'));
            return false;
          }

          // Ошибка авторизации
          if (
            err &&
            (PatientAuthenticationError.isAuthorizationError(err) ||
              PatientAuthenticationError.patientAlreadyMatched(err))
          ) {
            // return handleAuthorizationError(err, dispatch);
            setEhrError(t('screens.ehr.ehrAuthorizationError'));
            return false;
          }

          // Обрабатываем ошибку аутентификации
          if (err && PatientAuthenticationError.isAuthenticationError(err)) {
            // throw Error(t('screens.ehr.ehrAuthorizationError'));
            setEhrError(t('screens.ehr.ehrAuthorizationError'));
            return false;
          }

          // Обрабатываем остальные ошибки
          // Показать пользователю, что что-то пошло не так, дать сообщение с просьбой отправить тех. информацию
          if (err || !data || !data.patient) {
            setEhrError(t('screens.ehr.ehrErrorTitle'));
            return false;
          }
          setEhrError('');
          // Сохраняем данные аутентификации в локальный in_memory кеш

          if (data?.patient.id) {
            setPatientData({ variables: { id: data?.patient.id } });
            return true;
          }

          return false;
        },
      );
    });
  };

  const submitProfileHandler = (a: { variables: IUserInfo.UpdateUserInfoVariables }) => {
    if (config.MOCK_EHR) {
      setPatientData({ variables: { id: constants.MOCK_PATIENT_ID } });
      return;
    }
    // eslint-disable-next-line prefer-object-spread
    const patientInfo: PatientProperties = Object.assign({}, clientInfo, a.variables);
    if (ehrSubmitHandler && typeof ehrSubmitHandler === 'function') {
      const searchStrategy = patientInfo.medCardId ? 'MEDCARD' : 'PHONE';
      /* eslint-disable
          @typescript-eslint/no-unsafe-return,
          @typescript-eslint/no-unsafe-assignment,
          no-return-await
        */
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      ehrSubmitHandler(
        null,
        searchStrategy,
        (patientInfo as unknown) as PatientInputProperties,
        '',
      );
      /* eslint-enable
          @typescript-eslint/no-unsafe-return,
          @typescript-eslint/no-unsafe-assignment,
          no-return-await
          */
    } else {
      setEhrError('screens.ehr.ehrErrorTitle');
    }
  };

  const SkipHandler = () => {
    /* eslint-disable
      no-restricted-globals,
      @typescript-eslint/no-unsafe-call
      */
    history.back();
    /* eslint-enable
      no-restricted-globals,
      @typescript-eslint/no-unsafe-call
      */
  };

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  authPatient();

  return (
    <>
      <ProfileForm
        cred={cred}
        clientContext={clientContext}
        onSave={submitProfileHandler}
        showSkipBtn
        onSkip={SkipHandler}
        confirmButtonTitle={t('components.profileForm.applyButtonFind')}
        customLabel={t('screens.ehr.customProfileEditLabel')}
      />
      <ErrorBlock>{ehrError}</ErrorBlock>
    </>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const ErrorBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: 1,
  paddingBottom: unit * 6,
  color: 'red',
});
