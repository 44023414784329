import React, { Component } from 'react';
import styled from '@emotion/styled';
import { withTranslation, WithTranslation } from 'react-i18next';

import { urlManager } from 'utils/urlManager';
import { openWidget, SmallStartRecordItem } from 'components';
import { TStartRecordVariant } from '../data-layer/types';

import { ReactComponent as IconClinic } from '../assets/icon-clinic.svg';
import { ReactComponent as IconResource } from '../assets/icon-resource.svg';
import { ReactComponent as IconService } from '../assets/icon-service.svg';
import { breakpointMedium, breakpointSmall, unit } from '../styles';

interface smallStartRecordProps {
  variant: TStartRecordVariant;
  error?: boolean;
  className?: string;
}
interface smallStartRecordState {
  password: string;
}

class smallStartRecord extends Component<
  smallStartRecordProps & WithTranslation,
  smallStartRecordState
> {
  openClinicWidget = () => {
    openWidget({ networkId: urlManager.getNetworkId(), screen: 'location' });
  };

  openServiceWidget = () => {
    openWidget({ networkId: urlManager.getNetworkId(), screen: 'category' });
  };

  openResourceWidget = () => {
    openWidget({ networkId: urlManager.getNetworkId(), screen: 'resource' });
  };

  render() {
    const { t, className, variant } = this.props;
    return (
      <Container className={className} variant={variant}>
        {variant !== 'big' && <Title>{t('components.startRecord.title')}</Title>}
        <Inner variant={variant}>
          <SmallStartRecordItem
            variant={variant}
            title={t('components.startRecord.clinic')}
            onClick={this.openClinicWidget}
          >
            <IconClinic />
          </SmallStartRecordItem>
          <SmallStartRecordItem
            variant={variant}
            title={t('components.startRecord.service')}
            onClick={this.openServiceWidget}
          >
            <IconService />
          </SmallStartRecordItem>
          <SmallStartRecordItem
            variant={variant}
            title={t('components.startRecord.resource')}
            onClick={this.openResourceWidget}
          >
            <IconResource />
          </SmallStartRecordItem>
        </Inner>
      </Container>
    );
  }
}
export default withTranslation()(smallStartRecord);
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')(({ variant }: smallStartRecordProps) => {
  const styles: Partial<React.CSSProperties> = {
    display: 'flex',
    flexDirection: 'column',
  };
  switch (variant) {
    case 'big':
      return {
        width: '100%',
        margin: 'auto',
      };
    case 'card':
      return {
        ...styles,
        minHeight: unit * 24.5,
        marginBottom: unit * 2,
        paddingLeft: unit * 3,
        paddingRight: unit * 3,
      };
    case 'small':
    default:
      return {
        ...styles,
        minHeight: unit * 17,
      };
  }
});

const Title = styled('h3')({
  fontWeight: 900,
  fontSize: 20,
  lineHeight: 1.15,
  margin: `${unit * 2.6}px auto 0`,
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    marginLeft: 0,
    marginRight: 0,
  },
  [`@media screen and (max-width: ${breakpointSmall}px)`]: {
    [`[dir="ltr"] &`]: {
      marginLeft: unit,
    },
    [`[dir="rtl"] &`]: {
      marginRight: unit,
    },
  },
});

const Inner = styled('div')(({ variant }: smallStartRecordProps) => {
  const styles: Partial<React.CSSProperties> = {
    display: 'flex',
    justifyContent: 'space-evenly',
  };
  switch (variant) {
    case 'big':
      return {
        ...styles,
        margin: 'auto',
      };
    case 'card':
      return {
        ...styles,
        flexDirection: 'column',
        flexGrow: 1,
        margin: `${unit * 2.6}px auto 0`,
        [`@media screen and (max-width: ${breakpointMedium}px)`]: {
          marginLeft: 0,
          marginRight: 0,
        },
      };
    case 'small':
    default:
      return {
        ...styles,
        margin: 'auto 0',
      };
  }
});
