import React from 'react';
import styled from '@emotion/styled';

import { unit } from '../styles';
import { TStartRecordVariant } from '../data-layer/types';

interface SmallStartRecordItemProps extends React.HTMLAttributes<HTMLDivElement> {
  variant: TStartRecordVariant;
  title: string;
}

const SmallStartRecordItem = (props: SmallStartRecordItemProps): JSX.Element => {
  // eslint-disable-next-line react/prop-types
  const { title, children, variant, ...restProps } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container variant={variant} {...restProps}>
      {children}
      {['big', 'card'].includes(variant) && <Title>{title}</Title>}
    </Container>
  );
};

export default SmallStartRecordItem;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

interface IProps {
  variant: TStartRecordVariant;
}

const Container = styled('div')(({ variant }: IProps) => {
  const styles: Partial<React.CSSProperties> = {
    display: 'flex',
    flexDirection: variant === 'card' ? 'row' : 'column',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    maxWidth: unit * 102,
    fontWeight: 500,
    fontSize: 14,
  };
  switch (variant) {
    case 'big':
      return {
        ...styles,
        fontWeight: 400,
        fontSize: 26,
        lineHeight: 1.15,
        '&:hover': { transform: `translateY(-${unit * 0.2}px)` },
        '&:active': { opacity: 0.8 },
        svg: {
          width: unit * 8,
          height: unit * 8,
        },
      };
    case 'card':
      return {
        ...styles,
        lineHeight: 1.214285,
        svg: {
          width: unit * 4,
          height: unit * 4,
          [`[dir="ltr"] &`]: {
            marginRight: unit * 1.5,
          },
          [`[dir="rtl"] &`]: {
            marginLeft: unit * 1.5,
          },
        },
      };
    case 'small':
    default:
      return {
        ...styles,
        lineHeight: 1.214285,
        svg: {
          width: unit * 4,
          height: unit * 4,
        },
      };
  }
});

const Title = styled('h3')({
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
});
