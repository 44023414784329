import { gql } from 'apollo-boost';

export const GET_USER_INFO = gql`
  query GetUserInfo($user: String!, $token: String!) {
    getUserInfo(user: $user, token: $token) @client {
      name
      id
      email
      middleName
      surname
      birthday
      gender
      phone {
        area_code
        country_code
        number
      }
      medCardId
      passportId
    }
  }
`;
