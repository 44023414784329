import { gql } from 'apollo-boost';

export const GET_POPULAR_TAXONOMIES = gql`
  query GetPopularTaxonomies($networkID: String!) {
    getPopularTaxonomies(networkID: $networkID) @client {
      resources {
        name
        surname
        middleName
        id
        extraId
        siteId
      }
      taxonomies {
        name
        id
        extraId
        siteId
      }
      locations {
        id
        general_info {
          name
          shortName
        }
      }
    }
  }
`;
