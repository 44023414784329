import * as Moment from 'moment';
import { extendMoment, DateRange } from 'moment-range';
import { IFilterItem } from 'components';
import { GetEHRAppointments_getPatientAppointments_appointments } from 'data-layer/queries/__graphql__/GetEHRAppointments';
import { GetEHRDiagnostics_getEHRDiagnostics_diagnostics } from 'data-layer/queries/__graphql__/GetEHRDiagnostics';
import { GetAppointments_getAppointmentHistory_appointments } from 'data-layer/queries/__graphql__/GetAppointments';

const moment = extendMoment(Moment);

export const getEHRFilterValues = ({
  ehrApps,
  diagnostics,
  selectedResourcesKey,
  selectedServicesKey,
}: {
  ehrApps: GetEHRAppointments_getPatientAppointments_appointments[];
  diagnostics: GetEHRDiagnostics_getEHRDiagnostics_diagnostics[] | null[];
  selectedResourcesKey: string[];
  selectedServicesKey: string[];
}): {
  resources: IFilterItem[];
  taxonomies: IFilterItem[];
} => {
  const resList = new Map<string, IFilterItem>();
  const serviceList = new Map<string, IFilterItem>();
  if (ehrApps.length) {
    // eslint-disable-next-line no-unused-expressions
    ehrApps.forEach((app: GetEHRAppointments_getPatientAppointments_appointments) => {
      const res: IFilterItem = {
        name: `${app.doctor.surname} ${app.doctor.name}`,
        key: `${app.doctor.id}`,
        active: selectedResourcesKey.includes(`${app.doctor.id}`),
      };
      resList.set(res.key, res);

      (app.services || []).forEach((s) => {
        if (!s) {
          return;
        }
        const service: IFilterItem = {
          name: s.name,
          key: s.id,
          active: selectedServicesKey.includes(`${s.id}`),
        };
        serviceList.set(service.key, service);
      });
    });
  }
  if (diagnostics.length) {
    // eslint-disable-next-line no-unused-expressions
    diagnostics.forEach((app: GetEHRDiagnostics_getEHRDiagnostics_diagnostics | null) => {
      if (!app) {
        return;
      }

      (app.result || []).forEach((res) => {
        if (!res) {
          return;
        }
        const name = res.performerDoctor?.name || '';
        const surname = res.performerDoctor?.surname || '';
        const id = res.performerDoctor?.id || '';

        const resource: IFilterItem = {
          name: `${surname} ${name}`,
          key: `${id}`,
          active: selectedResourcesKey.includes(`${id}`),
        };
        if (resource.key) {
          resList.set(resource.key, resource);
        }
      });

      (app.services || []).forEach((s) => {
        if (!s) {
          return;
        }
        const service: IFilterItem = {
          name: s.name,
          key: s.id,
          active: selectedServicesKey.includes(`${s.id}`),
        };
        serviceList.set(service.key, service);
      });
    });
  }

  return {
    resources: Array.from(resList.values()),
    taxonomies: Array.from(serviceList.values()),
  };
};

export const getApiFilterValues = ({
  appointments,
  selectedResourcesKey,
  selectedServicesKey,
}: {
  appointments: (GetAppointments_getAppointmentHistory_appointments | null)[];
  selectedResourcesKey: string[];
  selectedServicesKey: string[];
}): {
  resources: IFilterItem[];
  taxonomies: IFilterItem[];
} => {
  const resList = new Map<string, IFilterItem>();
  const serviceList = new Map<string, IFilterItem>();
  appointments.forEach((app: GetAppointments_getAppointmentHistory_appointments | null) => {
    if (!app) {
      return;
    }
    const res: IFilterItem = {
      name: `${app.resource.surname} ${app.resource.name}`,
      key: `${app.resource.id}`,
      active: selectedResourcesKey.includes(`${app.resource.id}`),
    };
    if (res.key) {
      resList.set(res.key, res);
    }
    const s: IFilterItem = {
      name: `${app.taxonomy.alias}`,
      key: `${app.taxonomy.id}`,
      active: selectedServicesKey.includes(`${app.taxonomy.id}`),
    };
    serviceList.set(s.key, s);
  });
  return {
    resources: Array.from(resList.values()),
    taxonomies: Array.from(serviceList.values()),
  };
};

export const dateAppFilter = (
  app: GetAppointments_getAppointmentHistory_appointments | null,
  filterRanges: DateRange[],
): boolean => {
  return app && !!filterRanges.length
    ? filterRanges.some((range) => moment(app.appointment.start).within(range))
    : true;
};

export const dateEHRFilter = (
  app: GetEHRAppointments_getPatientAppointments_appointments,
  filterRanges: DateRange[],
): boolean => {
  return app && !!filterRanges.length
    ? filterRanges.some((range) => moment(app.start).within(range))
    : true;
};
export const dateEHRLabFilter = (
  app: GetEHRDiagnostics_getEHRDiagnostics_diagnostics,
  filterRanges: DateRange[],
): boolean => {
  return app && !!filterRanges.length
    ? filterRanges.some((range) => moment(app.issuedDate).within(range))
    : true;
};

export const resourceEHRFilter = (
  app: GetEHRAppointments_getPatientAppointments_appointments,
  selectedResources: IFilterItem[],
): boolean => {
  return app && !!selectedResources.length
    ? selectedResources.map((s) => s.key).includes(app.doctor.id)
    : true;
};
export const resourceEHRLabFilter = (
  app: GetEHRDiagnostics_getEHRDiagnostics_diagnostics,
  selectedResources: IFilterItem[],
): boolean => {
  const doctorIDs = app.result?.map((r) => r.performerDoctor?.id || '').filter((r) => !!r) || [];
  return app && !!selectedResources.length
    ? doctorIDs.some((d) => selectedResources.map((s) => s.key).includes(d))
    : true;
};

export const resourceAppFilter = (
  app: GetAppointments_getAppointmentHistory_appointments | null,
  selectedResources: IFilterItem[],
): boolean => {
  return app && !!selectedResources.length
    ? selectedResources.map((s) => s.key).includes(app.resource.id)
    : true;
};

export const serviceAppFilter = (
  app: GetAppointments_getAppointmentHistory_appointments | null,
  selectedServices: IFilterItem[],
): boolean => {
  return app && !!selectedServices.length
    ? selectedServices.map((s) => s.key).includes(app.taxonomy.id)
    : true;
};

export const serviceEHRFilter = (
  app: GetEHRAppointments_getPatientAppointments_appointments,
  selectedServices: IFilterItem[],
): boolean => {
  const serviceIDs = app.services?.map((s) => s.id) || [];
  return app && !!selectedServices.length
    ? serviceIDs.some((serviceID) => selectedServices.map((s) => s.key).includes(serviceID))
    : true;
};

export const serviceEHRLabFilter = (
  app: GetEHRDiagnostics_getEHRDiagnostics_diagnostics,
  selectedServices: IFilterItem[],
): boolean => {
  const serviceIDs = app.services?.map((s) => s.id) || [];
  return app && !!selectedServices.length
    ? serviceIDs.some((serviceID) => selectedServices.map((s) => s.key).includes(serviceID))
    : true;
};
