import moment from 'moment';
import React from 'react';
import styled from '@emotion/styled';
import { Link } from '@reach/router';

import { paths } from '../utils/routing';
import { colors, smallText, unit } from '../styles';
import { urlManager } from 'utils/urlManager';
import { GetEHRDiagnostics_getEHRDiagnostics_diagnostics } from 'data-layer/queries/__graphql__/GetEHRDiagnostics';
import { ReactComponent as IconLab } from '../assets/icon-lab.svg';

interface EHRDiagnosticBlockProps {
  appointmentData: GetEHRDiagnostics_getEHRDiagnostics_diagnostics;
}

type cardVariant = 'past' | 'future' | undefined;

export const EHRDiagnosticBlock: React.FC<EHRDiagnosticBlockProps> = React.memo(
  (props: EHRDiagnosticBlockProps) => {
    const { appointmentData } = props;
    const { id, result, services, resultInterpreter } = appointmentData;
    const start = result?.[0]?.createdDate || '';
    const queryString = urlManager.getQueryString();
    const cardVariant = 'past';
    const startTime = start ? moment.utc(start).calendar(null, { sameElse: 'D MMMM YYYY' }) : '';
    function getWorkerName() {
      if (resultInterpreter?.length) {
        return resultInterpreter
          .map((doctor) => {
            if (doctor) {
              const { name, surname } = doctor;
              return `${surname} ${name}`;
            }
            return '';
          })
          .join(',');
      }

      return '';
    }

    function getTitle() {
      let title = getWorkerName();
      if (services?.length && services?.[0]?.name) {
        title = services?.[0]?.name;
      }

      return `${title}`;
    }

    return (
      <Card variant={cardVariant} title={getTitle()}>
        <StyledLink to={`${paths.ehrDiagnosticPath(id)}?${queryString}`}>
          <IconLabStyled />
          <CardTitle>{getTitle()}</CardTitle>
          <CardDate>{startTime}</CardDate>
        </StyledLink>
      </Card>
    );
  },
);

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: cardVariant;
}

const Card = styled('div')((props: ICardProps) => {
  let background;
  switch (props.variant) {
    case 'past':
      background = colors.darkBackground;
      break;
    case 'future':
      background = colors.accentBackground;
      break;
    default:
      background = colors.mainBackground;
      break;
  }
  return {
    background,
    position: 'relative',
    boxShadow: `2px 2px 20px ${colors.shadow}`,
    borderRadius: unit * 1.5,
    padding: `${unit * 2}px ${unit * 2}px ${unit * 2.4}px`,
  };
});

const CardTitle = styled('h3')({
  fontWeight: 900,
  fontSize: 20,
  lineHeight: 1.15,
  color: colors.text,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  [`[dir="ltr"] &`]: {
    margin: `0 ${unit * 2}px ${unit * 2}px 0`,
  },
  [`[dir="rtl"] &`]: {
    margin: `0 0 ${unit * 2}px ${unit * 2}px`,
  },
});

const CardDate = styled('div')(smallText, {
  fontWeight: 500,
});

const StyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const IconLabStyled = styled(IconLab)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  [`[dir="ltr"] &`]: {
    right: unit * 2,
  },
  [`[dir="rtl"] &`]: {
    left: unit * 2,
  },
  path: {
    fill: colors.greyOpacity,
  },
});
