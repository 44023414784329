/* eslint-disable react/require-default-props */
import React from 'react';
import styled from '@emotion/styled';

import SideFilterButton from './SideFilterButton';
import { IEHRCounter, IEHRCounterType } from 'data-layer/types';
import { flexCenter, hideForMobile } from '../styles';

import { ReactComponent as IconDoctor } from '../assets/icon-doctor.svg';
import { ReactComponent as IconLab } from '../assets/icon-lab.svg';
import { ReactComponent as IconCardio } from '../assets/icon-cardio.svg';
import { ReactComponent as IconDoc } from '../assets/icon-doc.svg';

interface IItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  // eslint-disable-next-line react/require-default-props
  counts: IEHRCounter;
  active?: IEHRCounterType;
  setActive?: (a: IEHRCounterType) => void;
}

const SideFilters = ({
  counts,
  active = IEHRCounterType.doctor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setActive = (a: IEHRCounterType) => {
    // emty by default
  },
}: IItemProps): JSX.Element => {
  return (
    <Container>
      {counts.doctor > 0 && (
        <SideFilterButton
          data-count={counts?.doctor}
          active={active === IEHRCounterType.doctor}
          onClick={() => setActive(IEHRCounterType.doctor)}
        >
          <IconDoctor />
        </SideFilterButton>
      )}
      {counts.lab > 0 && (
        <SideFilterButton
          data-count={counts?.lab}
          active={active === IEHRCounterType.lab}
          onClick={() => setActive(IEHRCounterType.lab)}
        >
          <IconLab />
        </SideFilterButton>
      )}
      {counts.cardio > 0 && (
        <SideFilterButton
          data-count={counts?.cardio}
          active={active === IEHRCounterType.cardio}
          onClick={() => setActive(IEHRCounterType.cardio)}
        >
          <IconCardio />
        </SideFilterButton>
      )}
      {counts.doc > 0 && (
        <SideFilterButton
          data-count={counts?.doc}
          active={active === IEHRCounterType.doc}
          onClick={() => setActive(IEHRCounterType.doc)}
        >
          <IconDoc />
        </SideFilterButton>
      )}
    </Container>
  );
};

export default SideFilters;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('li')(flexCenter, hideForMobile, {
  margin: 'auto',
  flexDirection: 'column',
  '&:empty': {
    display: 'none',
  },
});
