import React, { useState } from 'react';

import ModalBasic from './ModalBasic';

import { urlManager } from 'utils/urlManager';

export interface IWidgetModalParams {
  networkId?: string;
  business?: string;
  screen?: string;
  taxonomy?: string;
  resourceId?: string;
}

export const MODAL_ELEMENT_ID = 'widget-modal';

// eslint-disable-next-line import/no-mutable-exports
export let openWidget: (params: IWidgetModalParams) => void | undefined;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WidgetModal = () => {
  const [opened, setOpened] = useState(false);
  const [widgetParams, setWidgetParams] = useState<IWidgetModalParams | undefined>();

  const insertWidget = (): void | undefined => {
    if (!widgetParams) {
      return;
    }
    if (!widgetParams.business && !widgetParams.networkId) {
      const businessId = urlManager.getBusinessId();
      const networkId = urlManager.getNetworkId();
      if (networkId) {
        widgetParams.networkId = networkId;
      } else if (businessId) {
        widgetParams.business = businessId;
      }
    }
    // eslint-disable-next-line no-unused-expressions
    window.GBooking && window.GBooking.openWidget(widgetParams);
  };

  openWidget = (params: IWidgetModalParams) => {
    setOpened(true);
    setWidgetParams(params);
  };

  const closeWidget = () => {
    // eslint-disable-next-line no-unused-expressions
    window.GBooking && window.GBooking.closeWidget();
    setOpened(false);
  };

  return (
    <ModalBasic
      variant="widget"
      id={MODAL_ELEMENT_ID}
      isOpen={opened}
      onAfterOpen={insertWidget}
      onRequestClose={closeWidget}
    />
  );
};

export default WidgetModal;
