import React from 'react';
import styled from '@emotion/styled';
import { Link } from '@reach/router';

import { breakpointLarge, unit } from '../styles';
import { paths } from '../utils/routing';
import { urlManager } from '../utils/urlManager';

const queryString = urlManager.getQueryString();
type LogoProps = {
  image: string;
  className?: string;
};

const Logo: React.FC<LogoProps> = ({ image, className }: LogoProps) => {
  return <StyledLogo image={image} className={className} to={`${paths.homePath}?${queryString}`} />;
};

export default Logo;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const StyledLogo = styled(Link)((props: LogoProps) => ({
  position: 'absolute',
  top: unit * 2.5,
  width: unit * 11.5,
  background: `url(${props.image}) left no-repeat`,
  backgroundSize: 'contain',
  flexShrink: 0,
  height: unit * 6,
  [`[dir="ltr"] &`]: {
    left: unit * 3.5,
  },
  [`[dir="rtl"] &`]: {
    right: unit * 3.5,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    top: unit,
    [`[dir="ltr"] &`]: {
      left: unit * 2,
    },
    [`[dir="rtl"] &`]: {
      right: unit * 2,
    },
    '.home &': {
      display: 'none',
    },
    '.login &, .profile-edit &': {
      position: 'static',
      backgroundPosition: 'top center',
      width: '100%',
      maxWidth: unit * 20,
      marginBottom: `5vh`,
    },
  },
}));
