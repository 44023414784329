/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { unit, colors } from '../styles';
import { navigate, Link, RouteComponentProps } from '@reach/router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PATIENT_ID } from 'data-layer/queries';
import { SET_PATIENT_ID } from 'data-layer/mutations';

import ModalDialog from 'components/ModalDialog';
import { paths } from 'utils/routing';
import { urlManager } from 'utils/urlManager';
import { useTranslation } from 'react-i18next';
import { PageContainer, EhrLogin } from 'components';
import { getMultilineTranslation } from '../utils';
import { GetEHRPatientId } from 'data-layer/queries/__graphql__/GetEHRPatientId';
import { SetPatientId, SetPatientIdVariables } from 'data-layer/mutations/__graphql__/SetPatientId';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EhrScreenProps extends RouteComponentProps {}

const EhrScreen: React.FC<EhrScreenProps> = () => {
  const [showModalInfo, setModalInfoShown] = useState(false);
  const { t } = useTranslation();

  const patientData = useQuery<GetEHRPatientId>(GET_PATIENT_ID);
  const [setPatientData, setPatientDataStatus] = useMutation<SetPatientId, SetPatientIdVariables>(
    SET_PATIENT_ID,
  );
  if (setPatientDataStatus.called) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    patientData.refetch();
  }
  const patientId = patientData.data?.patientId;
  useEffect(() => {
    if (!patientId) {
      return;
    }

    const queryString = urlManager.getQueryString();

    const timerId = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(`${paths.homePath}?${queryString}`);
    }, 5000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timerId);
  }, [patientId]);

  const pageTitle = t('screens.ehr.title');

  function renderModalInfo() {
    const queryString = urlManager.getQueryString();
    const content = (
      <>
        {getMultilineTranslation(t('modals.ehrInfo.content'))}
        <div>
          <span>{t('modals.ehrInfo.neverBeen')}</span>
          <StyledLink to={`${paths.newRecordPath}?${queryString}`}>
            {t('modals.ehrInfo.doAppointment')}
          </StyledLink>
          <span>{t('modals.ehrInfo.ehrAccess')}</span>
        </div>
      </>
    );

    const confirmButtonTitle = t('modals.ehrInfo.confirmBtn');
    const cancelButtonTitle = t('modals.ehrInfo.cancelBtn');

    const onConfirm = () => {
      setModalInfoShown(true);
    };

    const onCancel = () => navigate(`${paths.homePath}?${queryString}`);

    return (
      <ModalDialog
        isOpen={!showModalInfo}
        title={t('modals.ehrInfo.title')}
        contentLabel={t('modals.ehrInfo.title')}
        confirmButtonTitle={confirmButtonTitle}
        cancelButtonTitle={cancelButtonTitle}
        onConfirm={onConfirm}
        onCancel={onCancel}
        onRequestClose={onCancel}
      >
        {content}
      </ModalDialog>
    );
  }

  const showAuthSuccess = () => {
    return (
      <>
        <h2>Мы успешно авторизовали вас в ЭМК:)</h2>
        <h4>Этот экран закроется через 5 секунд</h4>

        <StyledLink to={`${paths.homePath}?${urlManager.getQueryString()}`}>
          Показать мои записи в мед карте
        </StyledLink>
      </>
    );
  };

  return (
    <PageContainer title={pageTitle} topNav sideNav>
      <Container>
        {!showModalInfo && renderModalInfo()}
        {!patientId && <EhrLogin setPatientData={setPatientData} />}
        {patientId && showAuthSuccess()}
      </Container>
    </PageContainer>
  );
};
export default EhrScreen;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: 1,
  paddingBottom: unit * 6,
});

const StyledLink = styled(Link)({
  color: colors.secondary,
  fontWeight: 'bold',
  textDecoration: 'none',
});
