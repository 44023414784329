/* eslint-disable react/require-default-props */
import moment from 'moment';
import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';

import { paths } from '../utils/routing';
import { breakpointMedium, cardBase, colors, smallText, unit } from '../styles';
import * as GetAppointmentsTypes from 'data-layer/queries/__graphql__/GetAppointments';
import { urlManager } from 'utils/urlManager';
import UserPic from './UserPic';
// import Location from './Location';
import { CardTelemedInfo } from './CardTelemedInfo';
import { AppointmentEditBlock } from './AppointmentEditBlock';
import { config, getAppintmentStart, ClientContext } from 'utils';
import CardDropdown, { ICardDropdownItemProps } from './CardDropdown';

// import { ReactComponent as IconHeart } from '../assets/icon-heart.svg';
import { ReactComponent as IconRedo } from '../assets/icon-redo.svg';

import { TCardVariant } from '../data-layer/types';
import { openWidget } from './WidgetModal';

interface AppointmentBlockProps {
  appointmentData: GetAppointmentsTypes.GetAppointments_getAppointmentHistory_appointments;
  // eslint-disable-next-line react/require-default-props
  variant?: TCardVariant;
  refetchFn?: (() => unknown) | undefined;
}

export const AppointmentBlock: React.FC<AppointmentBlockProps> = React.memo(
  ({ appointmentData, variant, refetchFn }: AppointmentBlockProps) => {
    const clientContext = useContext(ClientContext);
    const { t } = useTranslation();
    const [dropdown, setDropdown] = useState(false);
    // const [favorite, setFavorite] = useState(Math.random() > 0.5); // TODO: finish it
    const { appointment, resource, taxonomy, id, telemedData, businessID } = appointmentData;
    const { start } = appointment;
    const startDate = moment.utc(start).calendar(null, {
      sameElse: 'D MMMM HH:mm',
    });
    const queryString = urlManager.getQueryString();
    const cardVariant: TCardVariant = telemedData?.joinUrl ? 'telemed' : variant;
    const isEditBlockVisible = (['future', 'telemed'] as TCardVariant[]).includes(cardVariant);
    const appStart = getAppintmentStart(appointment, clientContext.timezone);

    function onDropdownShow() {
      setDropdown(true);
    }

    function onDropdownHide() {
      setDropdown(false);
    }

    // function onFavoriteClick() {
    //   setFavorite(!favorite);
    // }

    function onRedoClick() {
      openWidget({
        business: businessID,
        screen: 'time',
        resourceId: resource.id,
        taxonomy: taxonomy.id,
      });
    }

    function getWorkerName() {
      const { name, surname, middleName } = resource;
      return `${surname} ${name} ${middleName || ''}`;
    }

    function getWorkerIconSrc() {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return resource?.icon_url
        ? `${config.REACT_APP_CDN_WORKER_IMG || ''}${resource?.icon_url}`
        : '';
    }

    const dropdownItems: ICardDropdownItemProps[] = [
      // TODO: Faivorite apps
      // { icon: IconHeart, onClick: onFavoriteClick, selected: favorite },
      { icon: IconRedo, onClick: onRedoClick },
    ];

    const dateText = [startDate];

    if (cardVariant === 'telemed') {
      dateText.push(t('components.appointmentBlock.telemed'));
    } else {
      // TODO: push cabinet number
    }

    return (
      <Card variant={cardVariant}>
        <CardDropdown
          visible={dropdown}
          onShow={onDropdownShow}
          onHide={onDropdownHide}
          items={dropdownItems}
        />
        <StyledLink to={`${paths.appointmentPath(id)}?${queryString}`}>
          <CardTitle title={taxonomy?.alias || ''}>{taxonomy?.alias || ''}</CardTitle>
          <CardDate>{dateText.join(', ')}</CardDate>
          <CardResource>
            <CardResourcePic src={getWorkerIconSrc()} size={50} />
            {getWorkerName()}
          </CardResource>
          {/*
            TODO: add location
            cardVariant === 'future' && <Location title={'Медлайн на Покровке, Покровка 28'} /> */}
          {cardVariant === 'telemed' && <CardTelemedInfo appointmentStart={appStart} />}
        </StyledLink>
        {isEditBlockVisible && (
          <AppointmentEditBlock
            refetchFn={refetchFn}
            css="card"
            appointmentData={appointmentData}
          />
        )}
      </Card>
    );
  },
);

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: TCardVariant;
}

const Card = styled('div')(cardBase, ({ variant }: ICardProps) => {
  let background;
  let paddingTop = unit * 3;
  switch (variant) {
    case 'past':
      background = colors.darkBackground;
      paddingTop = unit * 2;
      break;
    case 'telemed':
      background = colors.accentBackground;
      break;
    default:
      background = colors.mainBackground;
      break;
  }
  return {
    background,
    position: 'relative',
    boxShadow: `2px 2px 20px ${colors.shadow}`,
    borderRadius: unit * 1.5,
    padding: `${paddingTop}px ${unit * 2}px ${unit * 2.4}px`,
  };
});

const CardTitle = styled('h3')({
  fontWeight: 900,
  fontSize: 20,
  lineHeight: '23px',
  color: colors.text,
  margin: 0,
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  [`[dir="ltr"] &`]: {
    paddingRight: unit,
  },
  [`[dir="rtl"] &`]: {
    paddingLeft: unit,
  },
});

const CardDate = styled('div')(smallText, {
  [`@media screen and (min-width: ${breakpointMedium + 1}px)`]: {
    fontWeight: 500,
  },
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    fontWeight: 400,
    fontSize: unit * 2,
  },
});

const CardResource = styled('h5')({
  display: 'flex',
  alignItems: 'center',
  margin: 0,
});

const CardResourcePic = styled(UserPic)({
  [`[dir="ltr"] &`]: {
    marginRight: unit * 1.5,
  },
  [`[dir="rtl"] &`]: {
    marginLeft: unit * 1.5,
  },
});

const StyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'inherit',
  display: 'block',
  '& > *:not(:last-child)': {
    marginBottom: unit * 2,
  },
});
