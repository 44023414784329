import React from 'react';
import styled from '@emotion/styled';
import { colors, sideNavButton, unit } from '../styles';

interface IItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

const SideFilterButton = styled('button')(sideNavButton, ({ active }: IItemProps) => ({
  position: 'relative',
  color: active ? colors.secondary : colors.greyOpacity,
  width: unit * 3.4,
  height: unit * 3.4,
  marginBottom: unit,
  'svg path': {
    fill: active ? colors.secondary : colors.greyOpacity,
  },
  '&:after': {
    content: 'attr(data-count)',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 0,
    color: 'inherit',
    fontWeight: 700,
    fontSize: unit,
    lineHeight: 0.833333,
    letterSpacing: '-0.05em',
  },
  '&:hover': {
    background: colors.darkBackground,
  },
}));

export default SideFilterButton;
