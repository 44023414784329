import React from 'react';
import styled from '@emotion/styled';

import { colors, flexCenter, fullSize, hideForMobile, unit } from '../styles';

import { ReactComponent as IconDots } from '../assets/icon-dots.svg';

export interface ICardDropdownItemProps {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  selected?: boolean;
  onClick: () => void;
}

interface ICardDropdownProps {
  visible: boolean;
  onShow: () => void;
  onHide: () => void;
  items: ICardDropdownItemProps[];
}

interface IIconProps {
  selected?: boolean;
}

const CardDropdown: React.FC<ICardDropdownProps> = ({
  visible,
  onShow,
  onHide,
  items,
}: ICardDropdownProps) => {
  return (
    <Wrapper>
      <TriggerButton onClick={onShow}>
        <IconDots />
      </TriggerButton>
      {visible && (
        <Dropdown>
          <Backdrop onClick={onHide} />
          {items.map(({ selected, onClick, icon }: ICardDropdownItemProps) => {
            const IconStyled = styled(icon)((props: IIconProps) => {
              if (props.selected) {
                return { path: { stroke: colors.accent } };
              }
              return {};
            });
            return (
              <TriggerButton key={onClick.toString()} onClick={onClick}>
                <IconStyled selected={selected} />
              </TriggerButton>
            );
          })}
        </Dropdown>
      )}
    </Wrapper>
  );
};

export default CardDropdown;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Wrapper = styled('div')(hideForMobile, {
  position: 'absolute',
  marginTop: `-${unit * 0.5}px`,
  [`[dir="ltr"] &`]: {
    right: unit,
  },
  [`[dir="rtl"] &`]: {
    left: unit,
  },
});

const TriggerButton = styled('button')(flexCenter, {
  width: unit * 3,
  height: unit * 3,
  padding: 0,
  border: 'none',
  background: 'none',
  outline: 'none',
  cursor: 'pointer',
});

const Backdrop = styled('div')(fullSize, {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: -1,
});

const Dropdown = styled('div')({
  background: colors.mainBackground,
  boxShadow: `${unit * 0.2}px ${unit * 0.2}px ${unit * 2}px ${colors.shadow}`,
  borderRadius: unit * 1.5,
  padding: unit * 0.5,
  position: 'absolute',
  top: 0,
  zIndex: 1,
  [`[dir="ltr"] &`]: {
    right: 0,
  },
  [`[dir="rtl"] &`]: {
    left: 0,
  },
});
