import React, { Component } from 'react';
import styled from '@emotion/styled';

import { hideForMobile, unit } from '../styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  GetPopularTaxonomies_getPopularTaxonomies_resources,
  GetPopularTaxonomies_getPopularTaxonomies_taxonomies,
  GetPopularTaxonomies_getPopularTaxonomies_locations,
} from 'data-layer/queries/__graphql__/GetPopularTaxonomies';
import { getKey, handleOpenWidgetClick } from 'utils/utils';
import NewRecordItem, { Item } from './NewRecordItem';

interface INewRecordProps {
  taxonomies: GetPopularTaxonomies_getPopularTaxonomies_taxonomies[];
  resources: GetPopularTaxonomies_getPopularTaxonomies_resources[];
  locations: GetPopularTaxonomies_getPopularTaxonomies_locations[];
}

// eslint-disable-next-line react/prefer-stateless-function
class NewRecord extends Component<INewRecordProps & WithTranslation> {
  taxonomyItem = (taxonomy: GetPopularTaxonomies_getPopularTaxonomies_taxonomies) => {
    return (
      <NewRecordItem uniqKey={getKey(taxonomy)} key={taxonomy.id} type="taxonomy">
        {taxonomy.name}
      </NewRecordItem>
    );
  };

  resourceItem = (resource: GetPopularTaxonomies_getPopularTaxonomies_resources) => {
    return (
      <NewRecordItem uniqKey={getKey(resource)} key={resource.id} type="resource">
        {resource.surname} {resource.name} {resource.middleName}
      </NewRecordItem>
    );
  };

  locationItem = (location: GetPopularTaxonomies_getPopularTaxonomies_locations) => {
    return (
      <NewRecordItem uniqKey={location.id || ''} key={location.id || ''} type="location">
        {location.general_info.name}
      </NewRecordItem>
    );
  };

  openClinicWidget = () => {
    handleOpenWidgetClick({ type: 'locations' });
  };

  openServiceWidget = () => {
    handleOpenWidgetClick({ type: 'taxonomies' });
  };

  openResourceWidget = () => {
    handleOpenWidgetClick({ type: 'resources' });
  };

  render() {
    const { t, resources, taxonomies, locations } = this.props;
    const btnTitle = t('components.newRecord.restButton');
    return (
      <Container>
        <Col>
          {locations.map((l) => this.locationItem(l))}
          <Item onClick={this.openClinicWidget}>{btnTitle}</Item>
        </Col>
        <Col>
          {taxonomies.map((tax) => this.taxonomyItem(tax))}
          <Item onClick={this.openServiceWidget}>{btnTitle}</Item>
        </Col>
        <Col>
          {resources.map((r) => this.resourceItem(r))}
          <Item onClick={this.openResourceWidget}>{btnTitle}</Item>
        </Col>
      </Container>
    );
  }
}
export default withTranslation()(NewRecord);
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')(hideForMobile, {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-evenly',
  paddingTop: unit * 5,
  width: '100%',
  marginBottom: 'auto',
});

const Col = styled('div')({
  alignItems: 'center',
  width: 'calc(100% / 3)',
  maxWidth: unit * 24,
  paddingLeft: unit * 0.5,
  paddingRight: unit * 0.5,
  flexGrow: 1,
  flexShrink: 1,
});
