import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Button, UserPic } from './index';
import { breakpointLarge, breakpointMedium, unit } from '../styles';

interface IProfilePicBlockProps {
  /* eslint-disable react/require-default-props */
  src?: string;
  className?: string;
  /* eslint-enable react/require-default-props */
}

const ProfilePicBlock: React.FC<IProfilePicBlockProps> = (props: IProfilePicBlockProps) => {
  const { t } = useTranslation();
  const { src, className } = props;
  const fileInput = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
  };

  return (
    <UserPicForm className={className}>
      <UserPic size={unit * 13} src={src} />
      <Input ref={fileInput} type="file" onChange={onFileChange} />
      <ButtonStyled variant="link" type="button" onClick={onClick}>
        {t('components.profileInfo.changePhoto')}
      </ButtonStyled>
    </UserPicForm>
  );
};

export default ProfilePicBlock;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const UserPicForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    top: unit * 4,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    top: unit * 2,
  },
  [`@media screen and (min-width: ${breakpointMedium + 1}px)`]: {
    position: 'absolute',
    left: unit * 2,
  },
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    alignItems: 'center',
  },
});

const ButtonStyled = styled(Button)({
  marginTop: unit * 2,
});

const Input = styled('input')({
  display: 'none',
});
