import React, { useState, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';

import {
  PageContainer,
  SmallStartRecord,
  AppointmentBlock,
  EHRAppointmentBlock,
  EHRDiagnosticBlock,
  Button,
  BottomFilter,
  IFilterItem,
  BlockControls,
  NewRecordScreenTable,
} from '../components';
import {
  GetAppointmentsVariables,
  GetAppointments,
  GetAppointments_getAppointmentHistory_appointments,
} from 'data-layer/queries/__graphql__/GetAppointments';
import { GET_APPOINTMENTS } from 'data-layer/queries';
import { urlManager } from 'utils/urlManager';
import styled from '@emotion/styled';

import { breakpointLarge, cardBase, colors, mediumText, size, unit } from '../styles';
import * as Moment from 'moment';
import { extendMoment, DateRange } from 'moment-range';

import { IAppFilter, IEHRCounterType } from 'data-layer/types';
import { GET_EHR_APPOINTMENTS } from 'data-layer/queries/getPatientAppointments';
import {
  GetEHRAppointments,
  GetEHRAppointmentsVariables,
} from 'data-layer/queries/__graphql__/GetEHRAppointments';
import { ClientContext, getUTCDateForCompare, usePostMessage } from 'utils';
import { GET_EHR_DIAGNOSTICS } from 'data-layer/queries/getEHRDiagnostics';
import {
  GetEHRDiagnostics,
  GetEHRDiagnosticsVariables,
} from 'data-layer/queries/__graphql__/GetEHRDiagnostics';
import {
  getEHRFilterValues,
  getApiFilterValues,
  resourceAppFilter,
  serviceAppFilter,
  dateAppFilter,
  dateEHRFilter,
  resourceEHRFilter,
  serviceEHRFilter,
  dateEHRLabFilter,
  resourceEHRLabFilter,
  serviceEHRLabFilter,
} from 'data-layer/helpers';

const moment = extendMoment(Moment);

type HomeScreenProps = RouteComponentProps;

const HomeScreen: React.FC<HomeScreenProps> = () => {
  const clientContext = useContext(ClientContext);
  const [ehrAppTypeFilter, setEhrAppTypeFilter] = useState(IEHRCounterType.doctor);
  const [appHistoryLoaded, setAppHistoryLoadedStatus] = useState(false);
  const LOAD_APP_SIZE = 6;
  const [selectedFilter, setAppointmentFilter] = useState(IAppFilter.all);
  const [pastAppointmentsLimit, setPastAppointmentLimit] = useState(LOAD_APP_SIZE - 1);
  const [selectedMonth, setSelectedMonth] = useState<IFilterItem[]>([]);
  const [selectedYear, setSelectedYear] = useState<IFilterItem[]>([]);
  const [selectedResources, setSelectedResource] = useState<IFilterItem[]>([]);
  const [selectedServices, setSelectedServices] = useState<IFilterItem[]>([]);
  const selectedResourcesKey = selectedResources.map((r: IFilterItem) => r.key);
  const selectedServicesKey = selectedServices.map((r: IFilterItem) => r.key);

  const bottomFilterActive =
    selectedYear.length > 0 || selectedResources.length > 0 || selectedServices.length > 0;

  const [bottomNav, setBottomNav] = useState(false);
  const onFilterToggle = () => setBottomNav(!bottomNav);

  const getSelectedFiltersString = (): string => {
    return [
      ...selectedYear.map((item) => item.name),
      ...selectedMonth.map((item) => item.name),
      ...selectedResources.map((item) => item.name),
      ...selectedServices.map((item) => item.name),
    ].join(', ');
  };

  const changeEhrAppTypeFilter = (a: IEHRCounterType) => {
    setEhrAppTypeFilter(a);
    setPastAppointmentLimit(LOAD_APP_SIZE - 1);
  };
  const filterChange = (key: IAppFilter) => {
    setAppointmentFilter(key);
  };
  const handleMoreClick = () => {
    setPastAppointmentLimit(pastAppointmentsLimit + LOAD_APP_SIZE);
  };
  const { t } = useTranslation();
  const appData = useQuery<GetAppointments, GetAppointmentsVariables>(GET_APPOINTMENTS, {
    variables: {
      clientID: clientContext.clientId,
      user: clientContext.user || '',
      token: clientContext.token || '',
      businessID: urlManager.getBusinessId(),
      networkID: urlManager.getNetworkId(),
    },
    skip: !clientContext.clientId,
    fetchPolicy: 'cache-and-network',
    onCompleted() {
      setAppHistoryLoadedStatus(true);
    },
  });
  const refetchAppointments = appData.refetch;

  usePostMessage({ refetchApp: refetchAppointments });

  const appEHRData = useQuery<GetEHRAppointments, GetEHRAppointmentsVariables>(
    GET_EHR_APPOINTMENTS,
    {
      variables: {
        ...clientContext,
        businessID: clientContext.businessId,
        offset: 0,
      },
      fetchPolicy: 'cache-and-network',
      skip: !clientContext.user || !clientContext.patientId || !clientContext.clientId,
    },
  );

  const diagnosticEHRData = useQuery<GetEHRDiagnostics, GetEHRDiagnosticsVariables>(
    GET_EHR_DIAGNOSTICS,
    {
      variables: {
        ...clientContext,
        businessID: clientContext.businessId,
      },
      fetchPolicy: 'cache-and-network',
      skip: !clientContext.user || !clientContext.patientId || !clientContext.clientId,
    },
  );

  const ehrApps = appEHRData.data?.getPatientAppointments?.appointments || [];
  const diagnostics = diagnosticEHRData.data?.getEHRDiagnostics.diagnostics || [];

  let appointments: (GetAppointments_getAppointmentHistory_appointments | null)[] =
    appData.data?.getAppointmentHistory.appointments || [];

  const withTelemed = appointments.some((app) => !!app?.telemedData?.joinUrl);
  let pageTitle = !appointments.length ? t('title.homeEmpty') : t('title.home');
  const now = moment.utc();
  switch (selectedFilter) {
    case IAppFilter.telemed:
      appointments = appointments.filter((a) => !!a?.telemedData?.joinUrl);
      pageTitle = t('title.teleApp');
      break;
    case IAppFilter.future:
      appointments = appointments
        .filter(
          (a) =>
            !!a &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            moment(getUTCDateForCompare(a.appointment.start, clientContext.timezone)).isAfter(now),
        )
        .sort((a, b) => moment(a?.appointment.start).diff(moment(b?.appointment.start)));
      pageTitle = t('title.futureApp');
      break;
    case IAppFilter.past:
      appointments = appointments.filter(
        (a) =>
          !!a &&
          moment(getUTCDateForCompare(a.appointment.start, clientContext.timezone)).isBefore(now),
      );
      pageTitle = t('title.pastApp');
      break;
    default:
      break;
  }

  const cleanFilters = () => {
    setSelectedMonth([]);
    setSelectedYear([]);
    setSelectedResource([]);
    setSelectedServices([]);
  };

  function renderButtomFilter() {
    const { resources, taxonomies } = clientContext.patientId
      ? getEHRFilterValues({ ehrApps, diagnostics, selectedResourcesKey, selectedServicesKey })
      : getApiFilterValues({ appointments, selectedResourcesKey, selectedServicesKey });
    const params = {
      selectedMonth,
      selectedYear,
      selectedMonthChange: setSelectedMonth,
      selectedYearChange: setSelectedYear,
      selectedResources,
      selectedResourcesChange: setSelectedResource,
      availableResources: resources,
      availableServices: taxonomies,
      selectedServices,
      selectedServicesChange: setSelectedServices,
      cleanFilters,
    };

    return <BottomFilter params={params} setBottomNav={setBottomNav} />;
  }

  let futureAppointments = appointments
    .filter(
      (app) =>
        !!app &&
        moment(getUTCDateForCompare(app.appointment.start, clientContext.timezone))
          .add(app.appointment.duration, 'minutes')
          .isAfter(now),
    )
    .sort((a, b) => moment(a?.appointment.start).diff(moment(b?.appointment.start)));
  const pastAppointments = appointments.filter(
    (app) =>
      !!app &&
      moment(getUTCDateForCompare(app.appointment.start, clientContext.timezone))
        .add(app.appointment.duration, 'minutes')
        .isBefore(now),
  );
  if (clientContext.patientId) {
    futureAppointments = [];
  }
  const filterRanges = useMemo((): DateRange[] => {
    const ranges: DateRange[] = [];
    if (selectedYear.length) {
      if (selectedMonth.length) {
        selectedMonth
          .map((m) => +m.key)
          .forEach((months) => {
            selectedYear
              .map((y) => +y.key)
              .forEach((year) => {
                const startDate = moment([year, months - 1]);
                const endDate = moment(startDate).endOf('month');
                ranges.push(moment.range(startDate, endDate));
              });
          });
      } else {
        selectedYear
          .map((y) => +y.key)
          .forEach((year) => {
            const startDate = moment([year, 0]);
            const endDate = moment(startDate).endOf('year');
            ranges.push(moment.range(startDate, endDate));
          });
      }
    }
    return ranges;
  }, [selectedYear, selectedMonth]);

  const filteredApps = useMemo(() => {
    return appointments
      .filter((a) => !!a && dateAppFilter(a, filterRanges))
      .filter((a) => resourceAppFilter(a, selectedResources))
      .filter((a) => serviceAppFilter(a, selectedServices));
  }, [appointments, filterRanges, selectedResources, selectedServices]);

  const filteredEHRApps = useMemo(() => {
    return ehrApps
      .filter((a) => dateEHRFilter(a, filterRanges))
      .filter((a) => resourceEHRFilter(a, selectedResources))
      .filter((a) => serviceEHRFilter(a, selectedServices));
  }, [ehrApps, filterRanges, selectedResources, selectedServices]);

  const filteredEHRDiagnosticApps = useMemo(() => {
    return diagnostics
      .filter((a) => dateEHRLabFilter(a, filterRanges))
      .filter((a) => resourceEHRLabFilter(a, selectedResources))
      .filter((a) => serviceEHRLabFilter(a, selectedServices));
  }, [diagnostics, filterRanges, selectedResources, selectedServices]);

  function FilteredAppointmentBlock() {
    const cards: JSX.Element[] = [];

    if (!clientContext.patientId && !!filteredApps.length) {
      filteredApps.forEach((app) => {
        if (app) {
          cards.push(
            <AppointmentBlock key={app.id} appointmentData={app} refetchFn={refetchAppointments} />,
          );
        }
      });
    }

    if (clientContext.patientId && !!ehrApps.length) {
      filteredEHRApps.forEach((app) => {
        if (app) {
          cards.push(<EHRAppointmentBlock key={app.resultId} appointmentData={app} />);
        }
      });
    }

    if (clientContext.patientId && !!diagnostics.length) {
      filteredEHRDiagnosticApps.forEach((app) => {
        if (app) {
          cards.push(<EHRDiagnosticBlock key={app.id} appointmentData={app} />);
        }
      });
    }

    return cards.length ? (
      <>
        <AppointmentsWrapper>
          <BlockControls
            selected={getSelectedFiltersString()}
            onFilterToggle={onFilterToggle}
            onFilterReset={cleanFilters}
          />
          {cards}
        </AppointmentsWrapper>
      </>
    ) : (
      <AppointmentsWrapper>
        <BlockControls
          selected={getSelectedFiltersString()}
          onFilterToggle={onFilterToggle}
          onFilterReset={cleanFilters}
        />
        <NoResults>{t('components.bottomFilters.noData')}</NoResults>
      </AppointmentsWrapper>
    );
  }

  function FutureAppointmentBlock() {
    const cards: JSX.Element[] = [];

    futureAppointments.forEach((app) => {
      if (app) {
        cards.push(
          <AppointmentBlock
            key={app.id}
            variant="future"
            appointmentData={app}
            refetchFn={refetchAppointments}
          />,
        );
      }
    });

    if (!futureAppointments.length) {
      return (
        <AppointmentsWrapper>
          <SmallStartRecord variant="small" />
          {clientContext.patientId && (
            <CardBlock>{t('components.startRecord.waitMessage')}</CardBlock>
          )}
        </AppointmentsWrapper>
      );
    }
    return (
      <AppointmentsWrapper>
        {cards}
        <SmallStartRecord variant="card" />
      </AppointmentsWrapper>
    );
  }

  function EHRAppointmentsBlock() {
    if (!appEHRData.data?.getPatientAppointments?.appointments?.[0]) {
      return <></>;
    }

    const pastAppointmentsToShow = ehrApps.slice(0, pastAppointmentsLimit);
    const cards = pastAppointmentsToShow.map(
      (app) => !!app && <EHRAppointmentBlock key={app.resultId} appointmentData={app} />,
    );
    return (
      <>
        <BlockControls
          title={t('screens.home.pastVisits')}
          selected={getSelectedFiltersString()}
          onFilterToggle={onFilterToggle}
          onFilterReset={cleanFilters}
        />
        <AppointmentsWrapper>
          {cards}
          {pastAppointmentsToShow.length < ehrApps.length && (
            <Button variant="transparent" onClick={handleMoreClick}>
              {t('screens.home.loadMore')}
            </Button>
          )}
        </AppointmentsWrapper>
      </>
    );
  }

  function EHRDiagnosticsBlock() {
    if (!diagnosticEHRData.data?.getEHRDiagnostics.diagnostics?.[0]) {
      return <></>;
    }

    const pastAppointmentsToShow = diagnostics.slice(0, pastAppointmentsLimit);
    const cards = pastAppointmentsToShow.map(
      (app) => !!app && <EHRDiagnosticBlock key={app.id} appointmentData={app} />,
    );

    return (
      <>
        <BlockControls
          title={t('screens.home.pastVisits')}
          selected={getSelectedFiltersString()}
          onFilterToggle={onFilterToggle}
          onFilterReset={cleanFilters}
        />
        <AppointmentsWrapper>
          {cards}
          {pastAppointmentsToShow.length < diagnostics.length && (
            <Button variant="transparent" onClick={handleMoreClick}>
              {t('screens.home.loadMore')}
            </Button>
          )}
        </AppointmentsWrapper>
      </>
    );
  }

  function PastAppointmentBlock() {
    if (!pastAppointments.length) {
      return <></>;
    }
    const pastAppointmentsToShow = pastAppointments.slice(0, pastAppointmentsLimit);
    const cards = pastAppointmentsToShow.map(
      (app) => !!app && <AppointmentBlock variant="past" key={app.id} appointmentData={app} />,
    );

    return (
      <>
        <BlockControls
          title={t('screens.home.pastVisits')}
          selected={getSelectedFiltersString()}
          onFilterToggle={onFilterToggle}
          onFilterReset={cleanFilters}
        />
        <AppointmentsWrapper>
          {cards}
          {pastAppointmentsToShow.length < pastAppointments.length && (
            <Button variant="transparent" onClick={handleMoreClick}>
              {t('screens.home.loadMore')}
            </Button>
          )}
        </AppointmentsWrapper>
      </>
    );
  }

  const showSideFilter =
    pastAppointments.length > 0 || diagnostics.length > 0 || ehrApps.length > 0;

  function showNonFilterAppointemnts() {
    return (
      <>
        <FutureAppointmentBlock />
        {!clientContext.patientId && <PastAppointmentBlock />}
        {ehrAppTypeFilter === IEHRCounterType.doctor && <EHRAppointmentsBlock />}
        {ehrAppTypeFilter === IEHRCounterType.lab && <EHRDiagnosticsBlock />}
      </>
    );
  }

  const layoutClassName = ['home', bottomFilterActive ? 'filters' : ''].join(' ');

  function renderAppointments() {
    return bottomFilterActive ? <FilteredAppointmentBlock /> : showNonFilterAppointemnts();
  }

  return (
    <PageContainer
      layoutClassName={layoutClassName}
      title={pageTitle}
      topNav
      sideNav
      backLink
      showAppFilter={!clientContext.patientId}
      withTelemed={withTelemed}
      sideFilterButton={showSideFilter}
      appFilter={selectedFilter}
      filterChange={filterChange}
      ehrAppTypeFilter={ehrAppTypeFilter}
      setEhrAppTypeFilter={changeEhrAppTypeFilter}
      bottomFilter={renderButtomFilter()}
      bottomNav={bottomNav}
      setBottomNav={setBottomNav}
      onBackClick={cleanFilters}
    >
      {appHistoryLoaded && !clientContext.patientId && !appointments.length ? (
        <NewRecordScreenTable />
      ) : (
        renderAppointments()
      )}
    </PageContainer>
  );
};
export default HomeScreen;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const AppointmentsWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  margin: `auto 0 auto -${size.cardOffset}px`,
  paddingTop: unit,
  minWidth: `calc(100% + ${size.cardOffset * 2}px)`,
  '& > *': {
    ...cardBase,
  },
  '& > button': {
    color: colors.secondary,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    marginTop: unit * 2,
  },
});

const CardBlock = styled('div')(mediumText, {
  position: 'relative',
  color: colors.greyOpacity,
  padding: `${unit * 3}px ${0}px ${unit * 1.5}px ${unit * 2}px`,
});

const NoResults = styled('div')({
  margin: `${unit * 3}vh auto 0`,
  textAlign: 'center',
});
