/* eslint-disable react/require-default-props */
import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';

import { colors } from '../styles';

const DEFAULT_SIZE = 50;
const DEFAULT_SRC = `http://robohash.org/${Math.random()}?set=3`;

interface UserPicProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
  src?: string;
  alt?: string;
  className?: string;
}

const UserPic: React.FC<UserPicProps> = ({
  size = DEFAULT_SIZE,
  src = DEFAULT_SRC,
  alt = '',
  className,
}: UserPicProps) => {
  return (
    <Container size={size} className={className}>
      <Img src={src} alt={alt} />
    </Container>
  );
};

export default UserPic;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')(({ size }: UserPicProps) => {
  const styles: Partial<CSSProperties> = {
    borderRadius: '50%',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: colors.shadow,
    flexShrink: 0,
  };
  if (size) {
    styles.width = size;
    styles.height = size;
  }
  return styles;
});

const Img = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});
