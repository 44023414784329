import styled from '@emotion/styled';

import { colors, smallText, unit } from '../styles';

const InputAlert = styled('span')(smallText, {
  position: 'absolute',
  left: '50%',
  top: `calc(100% + ${unit * 0.5}px)`,
  color: colors.accent,
  fontWeight: 400,
  transform: 'translateX(-50%)',
  whiteSpace: 'nowrap',
});

export default InputAlert;
