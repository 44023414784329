/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import styled from '@emotion/styled';

import Button from './button';
import Caret from './Caret';
import { breakpointLarge, colors, unit } from '../styles';
import { ReactComponent as IconError } from '../assets/icon-error.svg';

interface BottomFilterItemProps {
  title: string;
  selected?: string;
  onReset?: () => void;
  children?: React.ReactNode;
}

export const BottomFilterItem: React.FC<BottomFilterItemProps> = ({
  title,
  selected,
  onReset,
  children,
}: BottomFilterItemProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const handleReset = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    if (onReset) {
      onReset();
    }
  };
  return (
    <Container onClick={toggleDropdown} style={dropdownVisible ? { zIndex: 2 } : undefined}>
      <ButtonStyled variant="link">
        {selected && <IconErrorStyled onClick={handleReset} />}
        {selected || title}
        <Caret />
      </ButtonStyled>
      {dropdownVisible && children}
    </Container>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')({
  button: {
    position: 'relative',
    zIndex: 1,
  },
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    position: 'relative',
  },
});

const ButtonStyled = styled(Button)({
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    fontSize: unit * 2,
  },
});

const IconErrorStyled = styled(IconError)({
  width: unit * 2,
  height: unit * 2,
  [`[dir="ltr"] &`]: {
    marginRight: unit * 2,
  },
  [`[dir="rtl"] &`]: {
    marginLeft: unit * 2,
  },
  path: {
    stroke: colors.textAccent,
  },
});
