import React from 'react';
import styled from '@emotion/styled';

import { colors, flexCenter, unit } from '../styles';
import { ReactComponent as IconDelete } from '../assets/icon-delete.svg';

const ModalCloseButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = ({
  onClick,
}: React.HTMLAttributes<HTMLButtonElement>) => {
  return (
    <ModalCloseButtonStyled onClick={onClick}>
      <IconDelete />
    </ModalCloseButtonStyled>
  );
};

export default ModalCloseButton;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const ModalCloseButtonStyled = styled('button')(flexCenter, {
  position: 'absolute',
  top: unit,
  padding: 0,
  width: unit * 2,
  height: unit * 2,
  border: 'none',
  borderRadius: '50%',
  background: colors.secondary,
  outline: 'none',
  cursor: 'pointer',
  [`[dir="ltr"] &`]: {
    right: unit,
  },
  [`[dir="rtl"] &`]: {
    left: unit,
  },
  '&:hover': {
    opacity: '0.9',
  },
  svg: {
    width: unit,
    height: unit,
    path: {
      stroke: colors.mainBackground,
    },
  },
});
