import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React from 'react';

import { Business as BusinessData, BusinessVariables } from './__graphql__/Business';

// components
import JsonView from 'react-json-view';

const GET_BUSINESS = gql`
  query Business($businessId: ID!) {
    business(id: $businessId) @client {
      id
      resources {
        id
        name
      }
      taxonomies {
        id
        taxonomyType
      }
    }
  }
`;

export const Business = (): JSX.Element => {
  const businessId = '4000000005705';

  const businessQueryResult = useQuery<BusinessData, BusinessVariables>(GET_BUSINESS, {
    variables: { businessId },
  });

  return <JsonView name="businessQueryResult" src={businessQueryResult.data || {}} collapsed={1} />;
};
