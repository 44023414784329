import React from 'react';
import { IEHRCounter, Lang } from 'data-layer/types';
import { PatientProperties } from 'data-layer/helpers';
import moment from 'moment';
import { getBusiness_getBusinessByID_info } from 'data-layer/queries/__graphql__/getBusiness';
import { constants } from './config';

export interface IClientContext {
  clientId: string;
  clientInfo: PatientProperties;
  patientId: string;
  user: string;
  token: string;
  expires: string;
  businessId: string;
  ehrCounter: IEHRCounter;
  ehrEnabled: boolean;
  timezone: string;
  nationalMode: boolean;
  country: string;
  businessInfo?: getBusiness_getBusinessByID_info;
  refetchClient: () => void;
  lang: Lang;
}
const date = moment.utc().toDate();

export const defaultClientInfo: PatientProperties = {
  id: '',
  name: '',
  date,
  middleName: '',
  surname: '',
  gender: 0,
  email: '',
  phone: '',
  passportId: '',
};

export const defaultContext: IClientContext = {
  clientId: '',
  patientId: '',
  clientInfo: defaultClientInfo,
  user: '',
  token: '',
  expires: '',
  businessId: '',
  ehrCounter: {
    doctor: 0,
    lab: 0,
    doc: 0,
    cardio: 0,
  },
  ehrEnabled: false,
  timezone: 'Europe/Moscow',
  nationalMode: false,
  country: 'RU',
  businessInfo: undefined,
  refetchClient: () => {},
  lang: constants.LANGUAGES[0],
};

export const ClientContext = React.createContext(defaultContext);
