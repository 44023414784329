export interface IEHRCounter {
  doctor: number;
  lab: number;
  cardio: number;
  doc: number;
}

export enum IEHRCounterType {
  doctor,
  lab,
  cardio,
  doc,
}
