/**
 * Default translation for 'en_US' language
 */

// tslint:disable-next-line:variable-name export-name
export const en_us = {
  resource: 'Worker',
  common: {
    picker: {
      doneBtnLabel: 'Select',
      cancelBtnLabel: 'Cancel',
    },
    currency: {
      USD: 'USD',
      RUB: 'RUR',
      RUR: 'RUR',
      ILS: 'ILS',
    },
    priceType: {
      equal: '',
      begin_with: 'From',
      average: '',
      end: 'to',
    },
    errors: {
      RPC_ERROR__APPOINTMENT_RESOURCE_TAXONOMY_MISMATCH:
        'Sorry, this timeslot is already taken. Please, select another one.',
      RPC_ERROR__APPOINTMENT_RESOURCE_TAXONOMY_UNAVAILABLE:
        'Selected doctor no longer provides this service. Please, select different doctor',
      RPC_ERROR__APPOINTMENT_CONFIRM_FAILED: 'Selected time is taken',
      RPC_ERROR__APPOINTMENT_CLIENT_OVERLAPPED:
        'You have already taken this time. Please try booking another one',
      RPC_ERROR__APPOINTMENT_CABINET_OVERLAPPED:
        'The cabinet you have selected is already taken. Please try booking another cabinet',
      RPC_ERROR__APPOINTMENT_CPACITY_REACH:
        'This class is fully booked. Please try booking different time.',
      RPC_ERROR__APPOINTMENT_RESOURCE_BUSY:
        'Selected worker is taken. Consider booking different time, or another worker.',
      RPC_ERROR__CLIENT_IN_BLACKLIST: 'To make an appointment, please contact administrator',
      RPC_ERROR__APPOINTMENT_MIS_CONFIRM_FAILED:
        'Selected time in taken in the clinic. Consider booking different time, or another worker.',
      RPC_ERROR__APPOINTMENT_MIS_RESERVE_FAILED:
        'Selected time in taken in the clinic. Consider booking different time, or another worker.',
      RPC_ERROR_BODY:
        "We couldn't connect to the server. Please make sure that Internet is up and running.",
      RPC_ERROR_TITLE: 'Oops, seems like something has gone wrong!',
      SMS_CONFIRM_FAILED: 'SMS verification code is incorrect. Please try again',
      SMS_SEND_FAILED:
        "We couldn't send SMS. Please make sure that phone number you've entered is correct, then try again",
      SMS_CODE_INVALID: 'SMS verification code is incorrect. Please try again',
      JS_NO_SELECTED_RESOURCE: 'You did not select any worker!',
      JS_CRAC_NOT_ACTIVATED: 'CRAC is not activated',
      AUTH_EMAIL_TAKEN:
        'This user already exists! Please deactivate this user by dialing the Hotline Phone Number.',
      TIME_TO_CONFIRM_HAS_EXPIRED: 'Request timed out. Please try again later.',
    },
  },
  components: {
    confirmation: {
      approve: 'Confirm',
      withoutPayment: 'Pay in clinic',
      onlinePay: 'Pay now',
      ok: 'OK',
      cancel: 'Cancel',
      retry: 'Try again',
      confirmCode: 'Confirmation code',
      requestSmsCode: 'Request SMS code',
      notReciveCode: "Haven't received your confirmation code yet?",
      sendCodeAgain: 'Request the code once again',
      attention: 'Attention, You will book to the next services:',
      attentionDescription: '',
      minutesShort: 'min',
    },
    marker: {
      choose: 'Select',
    },
    search: {
      category: 'Speciality',
      resource: 'Doctor',
      business: 'Location',
      EmptySearch: 'Nothing found',
    },
    resource: {
      anyAvailable: 'Any available',
    },
    visitsList: {
      closeBtn: 'Close',
      locationTitle: 'Location',
      addressTitle: 'Address',
      routBtn: 'Find a rout',
      rescheduleBtn: 'Reschedule',
      cancelBtn: 'Cancel',
      noVisits: 'You have no scheduled visits',
      noVisitsText: 'To enroll, start by choosing a referral, branch or doctor',
      noVisitResult: 'No visit result',
    },
    misAuth: {
      form: {
        title: 'Fill your data',
        error: 'No match found. Check your data/',
        name: 'Fill your name and surname',
        insuranceNumber: 'Insuarence number',
      },
      approve: 'Confirm',
    },
    misSelect: {
      title: 'Select insurance type',
      skip: 'Book without insurance',
    },
    startScreen: {
      confirm: 'Continue',
    },
  },
  navigation: {
    profile: {
      history: 'Visits',
      list: 'Visits',
      title: 'Profile',
      logOut: 'Log out',
    },
    home: {},
    appointments: {
      confirm: 'Confirmation',
      category: 'Select speciality',
      location: 'Location',
      resource: 'Select specialist',
      service: 'Service selection',
      subcategory: 'Subcategory selection',
      time: 'Select date and time',
      cancel: 'Cancel',
      noTime:
        'Sorry, within next few weeks there are no available timeslots for this doctor. ' +
        'You can go back and select another speciality, doctor or location.',
      unaveilableResource:
        'To find this doctor please hit the button below to see the list of all doctors, then ' +
        'find the doctor you need manually or by using the search field at the top. ',
      unaveilableResourceBtn: 'Open the list of all doctors',
      unaveilableService:
        'To find this service please hit the button below to see the list of all services, then ' +
        'find the service you need manually or by using the search field at the top. ',
      unaveilableServiceBtn: 'Open the list of all services',
    },
    chat: {
      title: 'Chat',
    },
    onHome: {
      title: 'House call',
    },
    login: {
      title: 'Log in',
    },
    pin: {
      title: 'Password creation',
    },
    logout: {
      title: 'Log out',
    },
    maps: {
      title: 'Map view',
    },
    recipes: {
      title: 'Prescriptions',
    },
    powered: {
      gbooking: 'Powered by GBooking',
      medMe: 'Powered by Med.me',
    },
    sms: {
      title: 'SMS',
    },
    password: {
      title: 'Password',
    },
    agreement: {
      title: 'Agreement',
    },
    research: {
      title: 'Research',
    },
    visits: {
      title: 'Visits',
    },
    analysis: {
      title: 'Analysis',
    },
  },
  tabbar: {
    appointments: 'Appointments',
    profile: 'Profile',
    chat: 'Chat',
    onHome: 'House call',
    MedCard: 'Med. card',
    research: 'Research',
    visits: 'Visits',
    analysis: 'Analysis',
  },
  screens: {
    lock: {
      pinError: 'You have entered incorrect PIN',
    },
    ehr: {
      inputSurnamePlaceholder: 'Ваша фамилия',
      inputPatronymicPlaceholder: 'Ваше отчество',
      inputNamePlaceholder: 'Ваше имя',
      birthdayPlaceholder: 'Ваша дата рождения',
      loginInfoText: `Укажите свои фамилию и отчество,
       чтобы получить доступ
        к Медицинской карте`,
      ok: 'Ок!',
      hello: `Добрый день, \${name}!`,
      ehrErrorTitle: `\${name}, похоже,
       у вас ещё нет
        медицинской карты`,
      notYou: 'Это не вы?',
      loginWithAnotherNumber: 'Зайти под другим номером',
      callReception: `или позвоните в регистратуру по
      телефону \${phone}`,
      checkYourData: 'Или проверьте ваши данные',
      bookFirstAppointment: 'Запишитесь на первый приём',
      loginNumber: 'Вы зашли под номером',
      loginError: 'Заполните все поля',
      introduceYourself: 'Представьтесь, пожалуйста:',
    },
    appointments: {
      home: {
        btnsSelect: 'Select',
        visits: 'My visits',
        newFrom: {
          location: 'Select clinic location',
          profession: 'Select speciality',
          resource: 'Select doctor',
        },
        startHint: 'Start appointment creation:',
        errorSheetTitle: 'Network connection troubles detected!',
        errorSheetMessage:
          'Please make sure your internet connection is up and running, then try again.',
        reloadData: 'Reload data',
        cancel: 'Cancel',
        recentVisites: 'My last visits',
        startRecordingMessage: 'What would you like to start you booking from?',
        mainButton: {
          profession: {
            title: 'Specialities',
          },
          location: {
            title: 'Locations',
          },
          resource: {
            title: 'Specialist',
          },
        },
      },
      form: {
        nameLabel: 'Enter your full name',
        name: 'First Name',
        namePlaceholder: 'Enter your first name',
        surname: 'Family Name',
        surnamePlaceholder: 'Enter your family name',
        patronymic: 'Middle Name',
        patronymicPlaceholder: 'Enter your middle name',
        phone: 'Phone',
        professtion: 'Profession',
        insuranceNumber: 'Insurance number',
        direction: 'Speciality',
        address: 'Location',
        date: 'Date',
        dateTime: 'Date and Time',
        total: 'Total',
        doctor: 'Doctor',
        businessType: 'Branch',
        discount: 'with discount',
        category: 'Category',
        subcategory: 'Subcategory',
        service: 'Service',
        select: 'Please select…',
        time: 'Time',
        emailPlaceholder: 'example@email.com',
        emailLabel: 'Enter your e-mail',
        phoneLabel: 'Enter your phone number',
        phonePlaceholder: '+1 123 456-78-90',
        addressInfo: {
          notify:
            'This clinic currently does not support online booking. Consider making an appointment by phone',
        },
        SearchInputPlaceholder: 'Search by doctor, address or speciality',
        MedCardSearchInputPlaceholder: 'Doctor, diagnosis, medicine, etc.',
        SingleSearchInputPlaceholder: 'Search by doctor or speciality',
        title: 'Complete your appointment',
        description: 'Enter your details to complete the appointment.',
        notice: ' — REQUIRED fields',
        nameError: 'Invalid format',
        emailError: 'Invalid email',
        fio: 'Full name',
        genderLabel: 'Sex',
        clientComment: 'Provide us with extra details, if required',
        birthdayLabel: 'Your Date of Birth (dd.mm.yyyy)',
        birthdayLabelShort: 'Your Date of Birth',
        genderMale: 'M',
        genderFemale: 'F',
        genderSelect: 'Select gender',
      },
      category: {
        search: 'Search by speciality',
      },
      splitInsuranceClient: {
        insuranceClient: 'I am an insurance patient',
        commercialClient: 'I will cover the appointment by myself',
        return: 'Return',
        message: 'To make an appointment, please call',
      },
      subcategory: {
        title: 'Select speciality',
        search: 'Search by subcategories',
        skip: 'Skip to service selection',
      },
      service: {
        title: 'Select speciality',
        search: 'Search by speciality',
        from: 'from',
        to: 'to',
        beginWith: 'from',
        showDetails: 'Details',
        hideDetails: 'Hide description',
      },
      resources: {
        search: 'Search by specialist name and speciality',
        anyBtn: 'Any doctor',
        anyOverlay: 'Any doctor',
        all: 'All doctors',
        favorites: 'Favorite doctors',
        available: 'Available from ',
        onlyCall: 'Set appointment by phone',
        experience: 'Experience',
        notAvailable: 'Online appoint-ment N/A',
        clinic: 'Clinic',
        availableAddress: 'Doctor is available at this location:',
        about: 'About',
        nextAvailable: 'Next available date ',
        book: 'Book',
        desriptionTitle: 'DOCTOR INFO',
        closeBtn: 'Close',
      },
      address: {
        search: 'Search by address',
        listOption: 'Nearby',
        mapOption: 'Map view',
        anyOverlay: 'Any clinic',
        offline: 'Online booking is not available',
        measure: 'km',
        list: 'List view',
        map: 'Map view',
        book: 'Make an appointment',
        title: 'Select clinic',
        disable: 'Online booking is not available',
      },
      time: {
        businessError:
          'Sorry, but online booking is not configured yet for the selected clinic. ' +
          'You can go back and select different clinic.',
        error:
          'Sorry, but there are no available doctors for the selected speciality within upcoming few weeks. ' +
          'You can go back and select different speciality or location.',
        networkErrorTitle: 'An error occurred while loading data.',
        networkErrorMessage: 'Please make sure that internet is up and running, then try again.',
        ok: 'OK',
        showAvailableSlots: 'Show busy timeslots',
        errorResourceSlots:
          'Sorry, but there are no available timeslots for the selected doctor, we will ' +
          'show you available timeslots of another worker, instead',
        showDescription: "Show specialist's description",
        hideDescription: 'Hide description',
      },
      confirm: {
        total: 'Total',
        smsCodeHint:
          'Please complete this form to verify your phone number. We will send confirmation code to your phone number.',
        smsCodePlaceholder: 'Enter the code received via SMS',
        phonePlaceholder: 'Enter your phone number',
        birthdatePlaceholder: 'dd.mm.yyyy',
        done: 'Done',
        doneDescription: 'Your appointment has been successfully created',
        codeErrorTitle: 'You have entered incorrect code',
        phoneErrorTitle: 'You have entered incorrect phone number',
        codeErrorDescription: 'Please enter correct code',
        phoneErrorDescription: 'Correct format: +11231234567',
        thankYou: 'Your appointment has been successfully created! Stay healthy!',
        newRecord: 'Create another appointment',
        agreeText:
          'By hitting Confirm button below and opting in this checkbox, I&nbsp;agree to receive SMS notifications, as well as I provide consent&nbsp;to&nbsp;the&nbsp;processing of my personal data',
        agreeLink: 'Read more on that',
      },
      disable: {
        message: 'Online appointment creation is currently not available',
        text: `We are sorry for the inconvenience caused, but online booking
         is currenly out of operation in this business.<br /></br>
         To make an appointment, please call: </br>
        <a href='tel:\${cleanedPhone}'>\${phone}</a>`,
      },
    },
    profile: {
      history: {
        reRegister: 'Make another appointment',
        edit: 'Edit',
        cancel: 'Cancel',
        inactive: 'This appointment is inactive',
      },
      main: {
        twoFAUserDescription: 'PatientID (EHR)',
        appointmentBlockTitle: 'Appointment to the doctor',
        map: 'Route',
        connectedProfiles: 'LINKED PROFILES',
        authorizationWarning:
          'You need to create an appointment in order to get authenticated in the system.',
        select: 'Select',
        nearVisits: 'UPCOMING VISITS',
        historyOfVisits: 'History of visits',
        recipes: 'Prescriptions',
        createNew: 'Create new appointment',
        createNewInfo: "You don't have any appointments yet",
      },
      splash: `User profile is currently under development
It will be released soon, but you are welcome
to provide us with your feedback`,
    },
    common: {
      splash: {
        btnTitle: 'Create Feedback letter',
        emailTitle: 'Personal Cabinet of the Patient. Feedback form',
        emailBody: `Dear development team!
        My message:

        My name is:
        Please use the following phone number to get in touch with me: \${phone}

        Thank you`,
      },
    },
    recipes: {
      splash: `Module «Prescriptions» is currently under development
It will be released soon, but you are welcome
to provide us with your feedback`,
    },
    main: {
      splash: `Module «News and Special Offers» is currently under development
It will be released soon, but you are welcome
to provide us with your feedback`,
    },
    chat: {
      splash: `Module «Chat» is currently under development
It will be released soon, but you are welcome
to provide us with your feedback`,
    },
    onHome: {
      splash: `Module «House Call» is currently under development
It will be released soon, but you are welcome
to provide us with your feedback`,
    },
    login: {
      loginPlaceholder: 'Login',
      codePlaceholder: 'Code',
      enterBtnTitle: 'Enter',
      info: 'By registering, you accept the terms and conditions of the license agreement',
    },
    logout: {
      actiontitle: 'Profile deletion',
      actionmessage:
        'After completing this action, all your data will be deleted, and you will need to activate the app once again',
      closeAppMessage: 'Are you sure you want to close this application?',
      logOut: 'Quit',
      cancel: 'Return',
      closeApp: 'Exit app',
    },
    code: {
      error: 'The code you have entered is incorrect! Please try again.',
      resend: 'Send the code once again',
      delay: "You'll be able to request your new code once again in:",
      enterCode: 'Please enter below the code that has been sent to your phone number:',
    },
    maps: {
      splash: `Map view for clinics is currently under development
It will be released soon, but you are welcome
to provide us with your feedback`,
    },
    pin: {
      enterCode: 'Enter code',
      recoverCode: 'Forgot your code?',
      touchIdTitle: 'Confirm action',
      info: 'Create your permanent password OR enable TouchID',
    },
    eula: {
      info: 'To continue, please accept our license agreement',
    },
  },
};
