import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  USER_CRED,
  GET_PATIENT_ID,
  GET_CLIENT_DATA,
  GET_EHR_APPOINTMENTS,
  GET_NETWORK_DATA,
  GET_BUSINESS,
  USER_PHONE,
} from 'data-layer/queries';
import { UserCred } from 'data-layer/queries/__graphql__/UserCred';
import { GetEHRPatientId } from 'data-layer/queries/__graphql__/GetEHRPatientId';
import { GET_ANY_BUSINESSID } from 'data-layer/queries/geAnyBusinessID';
import {
  GetClientData,
  GetClientDataVariables,
} from 'data-layer/queries/__graphql__/GetClientData';
import { getAnyBusinessID } from 'data-layer/queries/__graphql__/getAnyBusinessID';
import {
  ClientContext,
  urlManager,
  IClientContext,
  defaultClientInfo,
  defaultContext,
  getNationalPhone,
  constants,
} from 'utils';
import { getLang } from '../utils/i18n';
import { GET_EHR_DIAGNOSTICS } from 'data-layer/queries/getEHRDiagnostics';
import {
  GetEHRAppointments,
  GetEHRAppointmentsVariables,
} from 'data-layer/queries/__graphql__/GetEHRAppointments';
import {
  GetEHRDiagnostics,
  GetEHRDiagnosticsVariables,
} from 'data-layer/queries/__graphql__/GetEHRDiagnostics';
import { IEHRCounter } from 'data-layer/types';
import { GET_USER_INFO } from 'data-layer/queries/getUser';
import { GetUserInfo, GetUserInfoVariables } from 'data-layer/queries/__graphql__/GetUserInfo';
import { PatientProperties, getPatientInfoFromProfile } from 'data-layer/helpers';
import {
  getNetworkDataVariables,
  getNetworkData,
} from 'data-layer/queries/__graphql__/getNetworkData';
import { getBusiness, getBusinessVariables } from 'data-layer/queries/__graphql__/getBusiness';
import { UserPhone } from 'data-layer/queries/__graphql__/UserPhone';

import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/he';

export const ClientContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const credData = useQuery<UserCred>(USER_CRED);
  const patientData = useQuery<GetEHRPatientId>(GET_PATIENT_ID);
  const anyBusinessData = useQuery<getAnyBusinessID>(GET_ANY_BUSINESSID, {
    skip: !urlManager.getNetworkId(),
  });
  const businessId = urlManager.getBusinessId() || anyBusinessData.data?.anyBusinessID || '';
  const businessData = useQuery<getBusiness, getBusinessVariables>(GET_BUSINESS, {
    variables: {
      id: businessId,
    },
  });
  const phoneData = useQuery<UserPhone>(USER_PHONE);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const nationalMode =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    businessData.data?.getBusinessByID?.info.backoffice_configuration.enablePhoneNationalMode ||
    false;
  const country =
    (phoneData.data?.phoneCountry as string) ||
    (businessData.data?.getBusinessByID?.info.general_info.address[0].country as string) ||
    'RU';

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const businessCountry = businessData.data?.getBusinessByID?.info.general_info.address[0]
    .country as string;
  const lang = getLang((businessCountry || '').toLocaleLowerCase());

  moment.locale(lang.tag);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const businessNetworkID: string =
    businessData.data?.getBusinessByID?.info.general_info.networkID || '';
  const networkData = useQuery<getNetworkData, getNetworkDataVariables>(GET_NETWORK_DATA, {
    variables: {
      networkID: urlManager.getNetworkId() || businessNetworkID,
    },
    skip: !urlManager.getNetworkId() && !businessNetworkID,
  });

  const user = credData.data?.user || '';
  const token = credData.data?.token || '';
  const expires = credData.data?.expires || '';
  const patientId = patientData.data?.patientId || '';
  const clientData = useQuery<GetClientData, GetClientDataVariables>(GET_CLIENT_DATA, {
    variables: {
      user,
      token,
      businessID: businessId,
    },
    skip: !credData.data || !businessId,
  });
  const refetchClient = () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      clientData.refetch();
    } catch (err) {}
  };

  const userData = useQuery<GetUserInfo, GetUserInfoVariables>(GET_USER_INFO, {
    variables: {
      user,
      token,
    },
    skip: !user || !clientData.data?.findOrCreateClient.id,
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let clientInfo: PatientProperties = defaultClientInfo;
  if (userData.data?.getUserInfo && clientData.data?.findOrCreateClient) {
    clientInfo = getPatientInfoFromProfile(
      userData.data?.getUserInfo,
      clientData.data?.findOrCreateClient,
    );
  }
  const clientId = clientData.data?.findOrCreateClient.id || '';
  const ehrCounter: IEHRCounter = {
    doc: 0,
    doctor: 0,
    lab: 0,
    cardio: 0,
  };
  const appEHRData = useQuery<GetEHRAppointments, GetEHRAppointmentsVariables>(
    GET_EHR_APPOINTMENTS,
    {
      variables: {
        user,
        token,
        clientId,
        patientId,
        businessID: businessId,
        offset: 0,
      },
      fetchPolicy: 'cache-only',
      skip: !patientId || !token || !clientId,
    },
  );

  const diagnosticEHRData = useQuery<GetEHRDiagnostics, GetEHRDiagnosticsVariables>(
    GET_EHR_DIAGNOSTICS,
    {
      variables: {
        user,
        token,
        clientId,
        patientId,
        businessID: businessId,
      },
      fetchPolicy: 'cache-only',
      skip: !patientId || !token || !clientId,
    },
  );

  if (diagnosticEHRData.data?.getEHRDiagnostics.diagnostics?.length) {
    ehrCounter.lab = diagnosticEHRData.data?.getEHRDiagnostics.diagnostics.length || 0;
  }
  if (appEHRData.data?.getPatientAppointments.appointments?.length) {
    ehrCounter.doctor = appEHRData.data?.getPatientAppointments.appointments.length || 0;
  }

  const ehrEnabled = networkData.data?.getNetwork?.integrationData?.ehr?.active || false;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const businessTimeZone =
    businessData.data?.getBusinessByID?.info?.general_info.timezone || defaultContext.timezone;

  if (nationalMode) {
    clientInfo.phone = getNationalPhone(clientInfo.phone, country);
  }

  const clientContext: IClientContext = {
    clientId,
    clientInfo,
    patientId,
    businessId,
    user,
    token,
    expires,
    ehrCounter,
    ehrEnabled,
    timezone: businessTimeZone,
    nationalMode,
    country,
    refetchClient,
    businessInfo: businessData.data?.getBusinessByID?.info,
    lang: lang || constants.LANGUAGES[0],
  };

  return <ClientContext.Provider value={clientContext}>{children}</ClientContext.Provider>;
};
