/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

import Caret from './Caret';

import { unit, colors, smallText, breakpointLarge, breakpointSmall } from '../styles';
import { IAppFilter } from 'data-layer/types';

interface IFilterItem {
  name: string;
  key: IAppFilter;
  active: boolean;
  order: number;
}

interface IAppFilterProps extends React.HTMLAttributes<HTMLDivElement> {
  selectedFilter: IAppFilter;
  withTelemed?: boolean;
  // eslint-disable-next-line react/require-default-props
  filterChange?: (filter: IAppFilter) => void;
}
export const TopNavAppFilter: React.FC<IAppFilterProps> = ({
  selectedFilter = IAppFilter.all,
  filterChange,
  withTelemed = false,
}: IAppFilterProps): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const filterItems: IFilterItem[] = [
    {
      name: t('components.TopNavAppFilter.allRecords'),
      key: IAppFilter.all,
      active: false,
      order: 0,
    },
    {
      name: t('components.TopNavAppFilter.futureRecords'),
      key: IAppFilter.future,
      active: false,
      order: 1,
    },
    {
      name: t('components.TopNavAppFilter.pastRecords'),
      key: IAppFilter.past,
      active: false,
      order: 2,
    },
  ];
  if (withTelemed) {
    filterItems.push({
      name: t('components.TopNavAppFilter.telemedRecords'),
      key: IAppFilter.telemed,
      active: false,
      order: 3,
    });
  }
  const [activeFilter, setActiveFilter] = useState(
    filterItems.find((f) => f.key === selectedFilter),
  );

  const onClickActive = () => {
    setVisible(!visible);
  };

  const selectFilterItem = (key: IAppFilter) => {
    setActiveFilter(filterItems.find((f) => f.key === key));
    if (filterChange) {
      filterChange(key);
    }
    setVisible(!visible);
  };

  const ShowInactiveFilterItems = () => {
    return (
      <ListInner>
        {filterItems
          .filter((f) => f.key !== activeFilter?.key)
          .map((f) => {
            return (
              <li key={f.key}>
                <Label onClick={() => selectFilterItem(f.key)}>{f.name}</Label>
              </li>
            );
          })}
      </ListInner>
    );
  };
  return (
    <ItemRoot>
      <MainLabel key={activeFilter?.key} onClick={onClickActive}>
        {activeFilter?.name}
        <CaretStyled />
      </MainLabel>
      {visible && <ShowInactiveFilterItems />}
    </ItemRoot>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const listStyles = css({
  listStyle: 'none',
  padding: 0,
  margin: 0,
});

const ListInner = styled('ul')(listStyles, {
  position: 'absolute',
  top: `-${unit}px`,
  left: `-${unit}px`,
  minWidth: `calc(100% + ${unit * 2}px)`,
  paddingTop: unit * 2.7,
  backgroundColor: colors.mainBackground,
  boxShadow: `2px 2px 20px ${colors.shadow}`,
  borderRadius: unit * 1.5,
  '& > li': {
    margin: unit,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    paddingTop: unit * 4,
  },
});

const Label = styled('span')(smallText, {
  color: colors.textAccent,
  cursor: 'pointer',
  textDecoration: 'none',
});

const MainLabel = styled(Label)({
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    fontWeight: 900,
    fontSize: unit * 2.6,
    lineHeight: 1.15,
    color: colors.text,
  },
  [`@media screen and (max-width: ${breakpointSmall}px)`]: {
    fontSize: unit * 2.2,
    whiteSpace: 'nowrap',
  },
});

const CaretStyled = styled(Caret)({
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    marginLeft: unit * 0.8,
    color: colors.secondary,
    fontSize: unit,
  },
});

const ItemRoot = styled('li')({
  position: 'relative',
  '& > span': {
    position: 'relative',
    zIndex: 1,
  },
});
