import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';

import Logo from './Logo';
import { breakpointLarge, unit, largeText, flexCenter, breakpointMedium } from '../styles';

interface HeaderProps {
  image: string;
  title: string;
  children: React.ReactNode;
}

const Header = ({ image = '', title = '', children = undefined }: HeaderProps): JSX.Element => (
  <StyledHeader hasNav={!!children}>
    {image && <Logo image={image} />}
    <Title title={title}>{title}</Title>
    {children}
    <Helmet>
      <title>{title}</title>
    </Helmet>
  </StyledHeader>
);

export default Header;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

type StyledHeaderProps = {
  hasNav: boolean;
};

const StyledHeader = styled('header')(flexCenter, ({ hasNav }: StyledHeaderProps) => {
  return {
    flexShrink: 0,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
      height: unit * 11,
      padding: `${unit * 2.5}px ${unit}px`,
      [`[dir="ltr"] &`]: {
        paddingLeft: `${unit * (hasNav ? 22.2 : 2.5)}px`,
      },
      [`[dir="rtl"] &`]: {
        paddingRight: `${unit * (hasNav ? 22.2 : 2.5)}px`,
      },
      h1: {
        [`[dir="ltr"] &`]: {
          marginRight: hasNav ? 'auto' : 0,
        },
        [`[dir="rtl"] &`]: {
          marginLeft: hasNav ? 'auto' : 0,
        },
      },
    },
    [`@media screen and (max-width: ${breakpointLarge}px)`]: {
      flexDirection: 'column',
      padding: unit * 2,
      [`[dir="ltr"] &`]: {
        paddingLeft: unit * 22.2,
      },
      [`[dir="rtl"] &`]: {
        paddingRight: unit * 22.2,
      },
      '.home &, .login &, .profile-edit &': {
        [`[dir="ltr"] &`]: {
          paddingLeft: unit * 2,
        },
        [`[dir="rtl"] &`]: {
          paddingRight: unit * 2,
        },
      },
      h1: {
        display: hasNav ? 'none' : 'block',
      },
    },
    [`@media screen and (max-width: ${breakpointMedium}px)`]: {
      [`[dir="ltr"] &`]: {
        paddingLeft: unit * 15,
      },
      [`[dir="rtl"] &`]: {
        paddingRight: unit * 15,
      },
    },
  };
});

const Title = styled('h1')(largeText, {
  margin: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    whiteSpace: 'nowrap',
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    textAlign: 'center',
  },
});
