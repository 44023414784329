import { gql } from 'apollo-boost';

export const GET_CLIENT_DATA = gql`
  query GetClientData($user: String!, $token: String!, $businessID: String!) {
    findOrCreateClient(user: $user, token: $token, businessID: $businessID) @client {
      name
      id
      address
      birthday
      email
      middleName
      surname
      phone {
        area_code
        country_code
        number
      }
      sex
      passportId
    }
  }
`;
