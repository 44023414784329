import React, { InputHTMLAttributes, memo } from 'react';
import styled from '@emotion/styled';
import { css, SerializedStyles, ClassNames } from '@emotion/core';
import InputMask from 'react-input-mask';

import { colors, unit } from '../styles';
import { ReactComponent as IconSuccess } from '../assets/icon-success.svg';
import { ReactComponent as IconError } from '../assets/icon-error.svg';
import { ReactComponent as IconVisible } from '../assets/icon-eye-open.svg';
import { ReactComponent as IconHidden } from '../assets/icon-eye-close.svg';
import { ReactComponent as IconSearch } from '../assets/icon-search.svg';
import { ReactComponent as IconCalendar } from '../assets/icon-calendar.svg';

export type iconType = 'success' | 'error' | 'visible' | 'hidden' | 'search' | 'calendar' | '';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  mask?: string | (string | RegExp)[];
  wrapperCSS?: SerializedStyles;
  icon?: iconType;
  onIconClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void | undefined;
  children?: React.ReactNode;
}

const Input = (props: InputProps): JSX.Element => {
  const { mask, wrapperCSS, onIconClick, children, ...restProps } = props;
  const renderIcon = () => {
    switch (props.icon) {
      case 'error':
        return <StyledIconError onClick={onIconClick} />;
      case 'success':
        return <StyledIconSuccess onClick={onIconClick} />;
      case 'hidden':
        return <StyledIconHidden onClick={onIconClick} />;
      case 'visible':
        return <StyledIconVisible onClick={onIconClick} />;
      case 'search':
        return <StyledIconSearch onClick={onIconClick} />;
      case 'calendar':
        return <StyledIconCalendar onClick={onIconClick} />;
      default:
        return '';
    }
  };
  const renderInput = () => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return mask ? <InputMaskStyled mask={mask} {...restProps} /> : <InputStyled {...restProps} />;
  };
  return (
    <ClassNames>
      {({ css: styles, cx }) => (
        <div className={cx(styles`${wrapperCSS}`, styles({ position: 'relative' }))}>
          {renderInput()}
          {renderIcon()}
          {children}
        </div>
      )}
    </ClassNames>
  );
};

Input.defaultProps = {
  mask: '',
  wrapperCSS: undefined,
  icon: undefined,
  onIconClick: undefined,
  children: undefined,
};

export default memo(Input);

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const icon = css({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  [`[dir="ltr"] &`]: {
    right: unit * 2,
  },
  [`[dir="rtl"] &`]: {
    left: unit * 2,
  },
});

const StyledIconError = styled(IconError)(icon);
const StyledIconSuccess = styled(IconSuccess)(icon);
const StyledIconVisible = styled(IconVisible)(icon);
const StyledIconHidden = styled(IconHidden)(icon);
const StyledIconSearch = styled(IconSearch)(icon);
const StyledIconCalendar = styled(IconCalendar)(icon);

const inputStyles = (props: InputProps) => {
  const offset = props.icon ? unit * 6.5 : unit * 1.3;

  return {
    width: '100%',
    maxWidth: unit * 29.2,
    padding: `${unit * 1.3}px ${unit * 2.4}px`,
    border: `1px solid transparent`,
    borderRadius: unit * 10,
    fontSize: 26,
    lineHeight: 1.153,
    backgroundColor: colors.darkBackground,
    transition: 'all 0.3s ease-in-out',
    ':focus': {
      borderColor: colors.secondary,
    },
    [`[dir="ltr"] &`]: {
      paddingRight: offset,
    },
    [`[dir="rtl"] &`]: {
      paddingLeft: offset,
    },
  };
};

const InputStyled = styled('input')(inputStyles);
const InputMaskStyled = styled(InputMask)(inputStyles);
