import { gql } from 'apollo-boost';

export const GET_NETWORK_BRANCH_LIST = gql`
  query getNetworkBranchList($networkID: ID!) {
    getNetworkBusinessList(id: $networkID) @client {
      businesses {
        businessID
        info {
          id
          general_info {
            logo_url
            name
            shortName
            address {
              country
              address
              latitude
              longitude
            }
            description
            phone {
              country_code
              area_code
              number
            }
            timezone
          }
          backoffice_configuration {
            enableExtendedPhone
            enablePhoneNationalMode
          }
          widget_configuration {
            requireAgreement
            requireAgreementLink
          }
        }
      }
    }
  }
`;
