/* eslint-disable react/require-default-props */
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { colors, flexCenter, showForMobile, smallText, unit } from '../styles';
import { ReactComponent as IconFilter } from '../assets/filter.svg';
import { ReactComponent as IconDelete } from '../assets/icon-delete.svg';

interface BlockControlsProps {
  title?: string;
  selected?: string;
  onFilterToggle: () => void;
  onFilterReset: () => void;
}

const BlockControls: React.FC<BlockControlsProps> = ({
  title,
  selected,
  onFilterToggle,
  onFilterReset,
}: BlockControlsProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <Wrapper>
        <ButtonStyled onClick={onFilterToggle} style={{ marginLeft: 'auto' }}>
          <Caption>{selected || t('screens.home.filter')}</Caption>
          {selected ? <IconFilterFilled /> : <IconFilter />}
        </ButtonStyled>
        {selected && (
          <ButtonStyled onClick={onFilterReset}>
            <IconDeleteStyled />
          </ButtonStyled>
        )}
      </Wrapper>
    </Container>
  );
};

export default BlockControls;

const Container = styled('div')(showForMobile, {
  width: '100%',
});

const Title = styled('h3')({
  fontWeight: 900,
  fontSize: unit * 2,
  lineHeight: 1.15,
  textAlign: 'center',
});

const Wrapper = styled('div')(flexCenter);

const ButtonStyled = styled('button')(flexCenter, smallText, {
  background: 'none',
  border: 'none',
  outline: 'none',
  padding: 0,
  fontFamily: 'inherit',
  color: colors.textAccent,
  margin: `0 ${unit * 0.6}px`,
});

const Caption = styled('span')({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: `calc(50vw - ${unit * 2.4}px)`,
  margin: `0 ${unit * 0.6}px`,
});

const IconFilterFilled = styled(IconFilter)({
  fill: colors.textAccent,
});

const IconDeleteStyled = styled(IconDelete)({
  width: unit * 2,
  height: unit * 2,
});
