import React from 'react';
import { useTranslation } from 'react-i18next';

import { LinkStyled } from './AppointmentEditBlock';
import { IAppointmentStart, TEditBlockCss } from '../data-layer/types';

interface StartConferenceButtonProps {
  joinUrl?: string;
  cssType: TEditBlockCss;
  appStart: IAppointmentStart;
}

export const StartConferenceButton: React.FC<StartConferenceButtonProps> = ({
  joinUrl,
  cssType,
  appStart,
}: StartConferenceButtonProps) => {
  const { t } = useTranslation();
  const { appStarted, appSoonStart } = appStart;
  let startBtnTxt = '';
  if (appSoonStart) {
    startBtnTxt = t('components.appointmentBlock.open');
  } else if (appStarted) {
    startBtnTxt = t('components.appointmentBlock.start');
  }
  if (joinUrl && (appSoonStart || appStarted)) {
    return (
      <LinkStyled css={cssType} href={joinUrl} target="_blank">
        {startBtnTxt}
      </LinkStyled>
    );
  }
  return null;
};
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */
