import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { Button, ModalDialog } from './index';
import { smallText, unit } from '../styles';

interface AgreementProps {
  value: boolean;
  src: string;
  onChange: (value: boolean) => void;
}

export const Agreement: React.FC<AgreementProps> = ({ value, src, onChange }: AgreementProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const onConfirm = () => {
    onChange(true);
    setModalVisible(false);
  };
  const onClose = () => {
    setModalVisible(false);
  };
  return (
    <Container>
      <ModalDialog
        isOpen={modalVisible}
        confirmButtonTitle={t('components.agreement.modalConfirm')}
        cancelButtonTitle={t('components.agreement.modalClose')}
        onConfirm={onConfirm}
        onCancel={onClose}
        onRequestClose={onClose}
      >
        <AgreementFrame src={src} />
      </ModalDialog>
      <AgreementLabel>
        <input type="checkbox" checked={value} onChange={(e) => onChange(e.target.checked)} />
        <span>{t('components.agreement.label')}</span>
      </AgreementLabel>
      <Button
        variant="link"
        type="button"
        onClick={() => {
          setModalVisible(true);
        }}
      >
        {t('components.agreement.info')}
      </Button>
    </Container>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')({
  '& > *': {
    textAlign: 'center',
  },
  button: {
    margin: 'auto',
  },
});

const AgreementLabel = styled('label')(smallText, {
  display: 'block',
  marginBottom: unit,
});

export const AgreementFrame = styled('iframe')({
  border: 'none',
  width: `calc(100vw - ${unit * 4}px)`,
  height: '100vh',
  maxHeight: unit * 60,
});
